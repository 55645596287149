import { FormatType } from "interfaces";

export const fieldsLookUpContract = [
  {
    title: "Origination Terms",
    name: "origination_terms",
    fields: [
      {
        label: "Pool Name",
        name: "pool_name",
      },
      {
        label: "Contract ID",
        name: "contract_id",
      },
      {
        label: "Effective Date",
        name: "effective_date",
        formatType: FormatType.date,
      },
      {
        label: "Service Account Number",
        name: "servicer_account_number",
      },
      {
        label: "Closing Date",
        name: "closing_date",
        formatType: FormatType.date,
      },
      {
        label: "Option Investment Amount",
        name: "option_investment_amount",
        formatType: FormatType.amount,
      },
      {
        label: "Acquisition Fee",
        name: "acquisition_fee",
        formatType: FormatType.amount,
      },
      {
        label: "Assignment Cost",
        name: "assignment_cost",
        formatType: FormatType.amount,
      },
      {
        label: "Tax Title Cost",
        name: "tax_title_cost",
        formatType: FormatType.amount,
      },
      {
        label: "Purchase Price - Primary",
        name: "purchase_price",
        formatType: FormatType.amount,
      },
      {
        label: "Purchase Price - Secondary",
        name: "purchase_price_secondary",
        formatType: FormatType.amount,
      },
      {
        label: "Risk Adjustment",
        name: "risk_adjustment",
        formatType: FormatType.percentNotDecimal,
      },
      {
        label: "HEI Percentage",
        name: "option_percentage",
        formatType: FormatType.percentNotDecimal,
      },
      {
        label: "Max IRR",
        name: "max_irr",
        formatType: FormatType.percentNotDecimal,
      },
      {
        label: "OTV",
        name: "otv",
        formatType: FormatType.percentNotDecimal,
      },
      {
        label: "LOTV",
        name: "lotv",
        formatType: FormatType.percentNotDecimal,
      },
      {
        label: "HEI Multiple",
        name: "hei_multiple",
        formatType: FormatType.variance,
      },
    ],
  },
  {
    title: "Mortgage Details",
    name: "mortgage_details",
    fields: [
      {
        label: "Appraised Value",
        name: "appraised_value",
        formatType: FormatType.amount,
      },
      {
        label: "1st Lien",
        name: "1st_lien",
        formatType: FormatType.amount,
      },
      {
        label: "2nd Lien",
        name: "2nd_lien",
        formatType: FormatType.amount,
      },
      {
        label: "Address",
        name: "address",
      },
      {
        label: "City",
        name: "city",
      },
      {
        label: "State",
        name: "state",
      },
      {
        label: "Zip Code",
        name: "zip_code",
      },
      {
        label: "County",
        name: "county",
      },
      {
        label: "MSA",
        name: "msa",
      },
      {
        label: "FICO Score",
        name: "fico_score",
        align: "right",
      },
    ],
  },
  {
    title: "Demographic Details",
    name: "demographics_details",
    fields: [
      {
        label: "Age",
        name: "age",
      },
      {
        label: "Marital Status",
        name: "marital_status",
      },
      {
        label: "Employment Status",
        name: "employment_status",
      },
      {
        label: "Salary",
        name: "salary",
        formatType: FormatType.amount,
      },
      {
        label: "Total Income",
        name: "total_income",
        formatType: FormatType.amount,
      },
    ],
  },
  {
    title: "Pledge Information",
    name: "pledge_information",
    fields: [
      {
        label: "Pledged To",
        name: "pledged_to",
      },
      {
        label: "Pledge Date",
        name: "pledge_date",
        formatType: FormatType.date,
      },
      {
        label: "Unpledged Date",
        name: "unpledged_date",
        formatType: FormatType.date,
      },
      {
        label: "Impaired",
        name: "impaired",
      },
    ],
  },
  {
    title: "Valuation Details",
    name: "valuation_details",
    fields: [
      {
        label: "Gross Uncapped Intrinsic",
        name: "gross_uncapped_hiev",
        formatType: FormatType.amount,
      },
      {
        label: "Gross Capped Intrinsic",
        name: "gross_capped_hiev",
        formatType: FormatType.amount,
      },
      {
        label: "Third Party Valuation",
        name: "third_party_valuation",
        formatType: FormatType.amount,
      },
      {
        label: "HPA at Origination",
        name: "hpa_at_origination",
      },
      {
        label: "HPA Current/Exit",
        name: "hpa_current_or_exit",
      },
      {
        label: "HPA Total Appreciation",
        name: "hpa_appreciation",
        formatType: FormatType.percentNotDecimal,
      },
    ],
  },
  {
    title: "Impaired Details",
    name: "impaired_details",
    fields: [
      {
        label: "Impaired",
        name: "impaired",
      },
      {
        label: "Foreclosure Sale Date",
        name: "foreclosure_sale_date",
        formatType: FormatType.date,
      },
      {
        label: "Bid Due Date",
        name: "bid_due_date",
        formatType: FormatType.date,
      },
      {
        label: "Bid Amount",
        name: "bid_amount",
        formatType: FormatType.amount,
      },
      {
        label: "Appraisal at Foreclosure",
        name: "appraisal_at_foreclosure",
        formatType: FormatType.amount,
      },
      {
        label: "Notes",
        name: "notes",
      },
    ],
  },
  {
    title: "Exit Details",
    name: "exit_details",
    fields: [
      {
        label: "Exit Date",
        name: "exit_date",
        formatType: FormatType.date,
      },
      {
        label: "Gross Repayment",
        name: "gross_repayment",
        formatType: FormatType.amount,
      },
      {
        label: "Final Home Value",
        name: "final_home_value",
        formatType: FormatType.amount,
      },
      {
        label: "Gross IRR",
        name: "gross_irr",
        formatType: FormatType.percentNotDecimal,
      },
      {
        label: "Net IRR",
        name: "net_irr",
        formatType: FormatType.percentNotDecimal,
      },
    ],
  },
];
