import { Button, Modal } from "components/common";
import s from "./FormUpdateData.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/reduxHooks";
import { AsyncThunk } from "@reduxjs/toolkit";

type Props = {
  disabled?: boolean;
  withCancelModal?: boolean;
  withSubmitModal?: boolean;
  onCancel: () => void;
  idForm: string;
  fetchDataAction: AsyncThunk<{}, any, any>;
  updateDataAction?: () => void;
  fetchData: any;
  isLoading: boolean;
  isUpdated: boolean;
  submitBtnText?: string;
  submitBtnModalText?: string;
  confirmSubmitTitle?: string;
  confirmSubmitSubtitle?: string;
  margin?: string;
};
export const FormUpdateDataButtons = ({
  disabled,
  withCancelModal = false,
  withSubmitModal = false,
  onCancel,
  idForm,
  fetchDataAction,
  updateDataAction,
  fetchData,
  isLoading,
  isUpdated,
  submitBtnText = "Submit",
  submitBtnModalText = "Update",
  confirmSubmitTitle = "Are you sure you want to update the values in the database?",
  confirmSubmitSubtitle = "",
  margin,
}: Props) => {
  const [confirmCancel, openConfirmCancel] = useState(false);
  const [confirmSubmit, openConfirmSubmit] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchData) {
      openConfirmSubmit(true);
    }
  }, [fetchData]);

  useEffect(() => {
    if (isUpdated) openConfirmSubmit(false);
  }, [isUpdated]);

  const onCancelUpdate = () => {
    withCancelModal ? openConfirmCancel(true) : onCancel();
  };

  const onSubmit = async () => {
    await dispatch(fetchDataAction(fetchData));
    updateDataAction && dispatch(updateDataAction());
  };

  return (
    <>
      <div className={s.btns} style={{ margin: margin ?? "" }}>
        <Button
          title="Cancel"
          onClick={onCancelUpdate}
          filled="empty"
          disabled={disabled}
          uppercase
        />
        <Button
          type="submit"
          form={idForm}
          title={submitBtnText}
          disabled={disabled}
          uppercase
        />
      </div>
      {confirmCancel && (
        <Modal
          isOpen={confirmCancel}
          onClose={() => openConfirmCancel(false)}
          title="Are you sure you want to cancel?"
          submitText="Yes"
          onSubmit={onCancel}
          isLoading={isLoading}
          loaderText="Processing..."
        >
          <p>The values you've entered will not be saved</p>
        </Modal>
      )}
      {confirmSubmit && (
        <Modal
          isOpen={confirmSubmit}
          onClose={() => openConfirmSubmit(false)}
          title={confirmSubmitTitle}
          subtitle={confirmSubmitSubtitle}
          submitText={submitBtnModalText}
          isLoading={isLoading}
          loaderText="Processing..."
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
