import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { UploadNotification, FormBlock } from "components/modules";
import {
  GENERATE_PROMO_STATUS,
  ORIGINATION_FEES_SWEET_IMPORT_API,
  ORIGINATOR_OPTIONS_BASE,
} from "const";
import { usePostQuery } from "hooks/usePostQuery";
import moment from "moment";
import { useState } from "react";
import {
  encodeDataToUrl,
  getUploadStatus,
  getUploadStatusStorage,
  getFileName,
  getInitialFileName,
  getLoading,
} from "utils";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useFormSelect } from "hooks/useFormSelect";
import { useFormSelectDate } from "hooks/useFormSelectDate";
import { useFormSelectFile } from "hooks/useFormSelectFile";

export const OriginationFeesSweep = () => {
  const pageName = "originationFeesSweep";

  const { value: originator, handleSelect, resetSelect } = useFormSelect("");

  const {
    value: startDate,
    handleDateChange,
    resetDate,
  } = useFormSelectDate(new Date());

  const {
    value: selectedFile,
    handleFileUpload,
    handleFileRemove,
  } = useFormSelectFile(() => setShowUploadNotification(false));
  const [showUploadNotification, setShowUploadNotification] = useState(
    localStorage.getItem(pageName) || false
  );

  const [succeedFileName, setSucceedFileName] = useState("");

  const { changeStorage } = useLocalStorage(pageName, () => {
    setSucceedFileName(getInitialFileName(pageName));
  });

  const { loading, error, postFormData, responseStatus } = usePostQuery(
    ORIGINATION_FEES_SWEET_IMPORT_API,
    changeStorage
  );
  const { post: generatePromoStatus } = usePostQuery(GENERATE_PROMO_STATUS);

  const resetValues = () => {
    resetSelect();
    resetDate();
    handleFileRemove();
  };

  const handleImport = async () => {
    try {
      setShowUploadNotification(true);
      const requestData = new FormData();
      const startDateFormatted = moment(startDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      const query = encodeDataToUrl({
        model: originator,
        date: startDateFormatted,
      });

      selectedFile && requestData.append("file", selectedFile);

      const result = await postFormData(
        requestData,
        query,
        pageName,
        selectedFile?.name
      );
      if (result?.status) {
        resetValues();
        if (result?.status === 200) {
          generatePromoStatus(null);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const status = getUploadStatus(loading, responseStatus);

  const renderRightContent = () => {
    return (
      <UploadNotification
        status={getUploadStatusStorage(pageName, status)}
        fileName={getFileName(
          pageName,
          selectedFile?.name || "",
          status,
          succeedFileName
        )}
        closeNotification={() => setShowUploadNotification(false)}
        pageName={pageName}
      />
    );
  };

  return (
    <PageContent title="Origination Fees Sweep" path="Imports">
      <PageFormLayout
        rightContent={showUploadNotification ? renderRightContent() : null}
      >
        <>
          <FormBlock
            fields={{
              select1: {
                options: ORIGINATOR_OPTIONS_BASE,
                onSelect: handleSelect,
                value: originator,
                fieldName: "Select Originator",
              },
              file: {
                onRemove: handleFileRemove,
                file: selectedFile,
                setFile: handleFileUpload,
              },
              startDate: {
                date: startDate,
                setDate: (value: Date | undefined | null) =>
                  handleDateChange(value),
              },
            }}
            importButton={{
              disabled: !selectedFile || !originator || !startDate,
              loading: getLoading(loading, pageName),
              loadingText: "IMPORTING...",
              onclick: handleImport,
            }}
          />
        </>
      </PageFormLayout>
    </PageContent>
  );
};
