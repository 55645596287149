import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { GET_UPDATE_CONTRACT_DATA, UPDATE_CONTRACT_DATA } from "const";
import { encodeDataToUrl } from "utils";
import {
  UpdateContractDataFirstType,
  UpdateContractDataSecondType,
  UpdateContractDataThirdType,
} from "store/types/UpdateContractType";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const cookies = new Cookies();

export const getUpdateContractData = createAsyncThunk(
  "contractUpdate",
  async (
    _payload: {
      contract_id: string;
    },
    thunkApi
  ) => {
    try {
      const queryParams = encodeDataToUrl(_payload);

      const response = await fetchWithAuth(
        `${GET_UPDATE_CONTRACT_DATA}?${queryParams}`,
        {
          method: "get",
          headers: {
            authorization: "Bearer " + cookies.get("token"),
          },
        }
      );
      const parsed = await response.json();
      const { data } = parsed;

      if (response.status !== 200) {
        throw new Error(response.statusText + response.status);
      }
      return thunkApi.fulfillWithValue(data);
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const updateContractData = createAsyncThunk(
  "contract/update",
  async (
    _payload:
      | UpdateContractDataFirstType
      | UpdateContractDataSecondType
      | UpdateContractDataThirdType,
    thunkApi
  ) => {
    try {
      await fetchWithAuth(`${UPDATE_CONTRACT_DATA}`, {
        method: "put",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(_payload),
      });

      return thunkApi.fulfillWithValue({});
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
