import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlockSearchByContract } from "components/modules/FormBlock/FormBlockSearchByContract";
import { FormDeleteContract } from "components/modules/FormUpdateData/FormDeleteContract";
import { SuccessNotification } from "components/modules/UploadNotification/SuccessNotification";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useGoBackInUpdateForm } from "hooks/useGoBackInUpdateForm";
import { useEffect } from "react";
import { getDeleteContractData } from "store/actions/DeleteContractActions";
import { resetData, resetUpdated } from "store/reducers/DeleteContractSlice";
import { ListSkeleton } from "../../modules";

export const DeleteContractDetails = () => {
  const { data, isLoading, isUpdated } = useAppSelector(
    (state) => state.deleteContract
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  const { secondScreenMode, onGoBack } = useGoBackInUpdateForm(
    data.contract_id,
    resetData
  );

  const renderRightContent = () => {
    return (
      <SuccessNotification
        title="The Conract"
        description="had been successfully deleted!"
        onClose={() => dispatch(resetUpdated())}
      />
    );
  };

  return (
    <PageContent
      title="Delete Contract Details"
      path="Record Management"
      secondScreenMode={secondScreenMode}
      onGoBack={onGoBack}
    >
      <PageFormLayout rightContent={isUpdated ? renderRightContent() : null}>
        <>
          <FormBlockSearchByContract
            getDataAction={getDeleteContractData}
            resetUpdated={resetUpdated}
            isLoading={isLoading}
            loadedContractId={data.contract_id}
          />
          {!isLoading ? (
            secondScreenMode &&
            data.contract_id && (
              <FormDeleteContract
                data={data}
                isLoading={isLoading}
                isUpdated={isUpdated}
              />
            )
          ) : (
            <ListSkeleton count={16}></ListSkeleton>
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
