import { SortType, FilterType, FormatType } from "interfaces";
import { INVESTMENT_TERM_FILTER } from "./selectsOptions";
import { actionTypesHistory, statusHistory } from "./history";

export const columnsActualReturnsReport = [
  {
    label: "Contract ID",
    name: "contract_id",
    sortType: SortType.alphabet,
  },
  {
    label: "Effective Date",
    label2: "(Primary)",
    name: "effective_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Closing Date",
    name: "closing_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Effective Date",
    label2: "(Secondary)",
    name: "effective_date_second",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Exit Date",
    name: "exit_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Days To Exit",
    name: "days_to_exit",
    sortType: SortType.num,
  },
  {
    label: "Investment Amount",
    name: "investment_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Purchase Price",
    label2: "(Primary)",
    name: "purchase_price",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Purchase Price",
    label2: "(Secondary)",
    name: "purchase_price_second",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Latest Valuation",
    name: "latest_valuation",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Servicing Fees",
    label2: "Paid (Primary)",
    name: "servicing_fees_paid",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Servicing Fees",
    label2: "Paid (Secondary)",
    name: "servicing_fees_paid_second",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Gross Repayment",
    label2: "Amount",
    name: "gross_repayment_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },

  {
    label: "Disposition",
    label2: "Fees Paid",
    name: "disposition_fees_paid",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Net Repayment",
    label2: "Amount",
    name: "net_repayment_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Multiple",
    name: "multiple",
    sortType: SortType.num,
  },
  {
    label: "Max IRR",
    name: "max_irr",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Gross IRR",
    name: "gross_irr",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Net IRR",
    label2: "(Primary)",
    name: "net_irr",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Net IRR",
    label2: "(Secondary)",
    name: "net_irr_second",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "MSA",
    name: "metropolitan_name",
    sortType: SortType.alphabet,
  },
  {
    label: "HPA at Inception",
    label2: "(Primary)",
    name: "hpa_inception",
    sortType: SortType.num,
  },
  {
    label: "HPA at Inception",
    label2: "(Secondary)",
    name: "hpa_inception_second",
    sortType: SortType.num,
  },
  {
    label: "HPA at Exit",
    name: "hpa_exit",
    sortType: SortType.num,
  },
  {
    label: "Annualized HPA",
    label2: "Change (Primary)",
    name: "hpa_change",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Annualized HPA",
    label2: "Change (Secondary)",
    name: "hpa_change_second",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Appraised Value",
    label2: "at Origination",
    name: "appraised_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Final Home",
    label2: "Value",
    name: "final_home_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
];

export const columnsExitsVarianceReport = [
  {
    label: "Contract ID",
    name: "contract_id",
    sortType: SortType.alphabet,
  },
  {
    label: "Effective Date",
    name: "effective_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Investment Amount",
    name: "investment_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Appraised Value",
    name: "appraised_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },

  {
    label: "Risk Adjustment",
    name: "risk_adjustment",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Original Agreed",
    label2: "Value",
    name: "original_agreed_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "HEI Percentage",
    name: "hei_percentage",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Homeowner",
    label2: "Protection Cap",
    name: "homeowner_protection_cap",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Max IRR",
    name: "max_irr",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Promo cap",
    name: "promo_cap",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Exit Value",
    name: "exit_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Payoff Date",
    name: "payoff_date",
    sortType: SortType.num,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Total HPA",
    name: "total_hpa",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Annualized HPA",
    name: "annualized_hpa",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Appreciation Share",
    name: "appreciation_share",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Appreciation Share +",
    label2: "Investment Amount",
    name: "appreciation_share_investment_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Capped Repayment",
    label2: "Amount",
    name: "capped_repayment_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Expected Repayment",
    label2: "Amount Before Fees",
    name: "expected_repayment_amount_before_fees",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Expected Refund",
    name: "expected_refund",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Actual Amount",
    label2: "Repaid by Originator",
    name: "actual_amount_repaid_by_originator",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Implied Gross IRR",
    name: "implied_gross_irr",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Gross IRR",
    name: "gross_irr",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Variance",
    name: "variance",
    sortType: SortType.num,
    formatType: FormatType.variance,
  },
];

export const columnsSmmResolutionRates = [
  {
    label: "Month End Date",
    name: "month_end_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Net Funded Balance",
    label2: "of Receivables",
    name: "net_funded_balance_of_receivables",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Collections on",
    label2: "Realized Receivables",
    name: "collections_on_realized_receivables",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "SMM",
    name: "smm",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Resolution Rate",
    name: "resolution_rate",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Net Funded Balance",
    label2: "of Realized Receivables",
    name: "net_funded_balance_of_realized_receivables",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Collections Ratio",
    name: "collections_ratio",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "3-Mo Resolution",
    name: "3-mo_average_resolution_rate",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "6-Mo Resolution",
    name: "6-mo_average_resolution_rate",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "12-Mo Resolution",
    name: "12-mo_average_resolution_rate",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
];

export const columnsPledgeDebtLine = [
  {
    label: "Investment ID",
    name: "investment_id",
    sortType: SortType.alphabet,
  },
  {
    label: "Investment Amount",
    name: "investment_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Investment Term",
    name: "investment_term",
    sortType: SortType.num,
    filterType: FilterType.select,
    filterSelectOptions: INVESTMENT_TERM_FILTER,
  },
  {
    label: "Effective Date",
    name: "effective_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Closing Date",
    name: "closing_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Pledge Date",
    name: "pledged_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Unpledge Date",
    name: "unpledge_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Purchase Price",
    name: "purchase_price",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Appraised Value",
    name: "appraised_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "OTV",
    name: "otv",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "LOTV",
    name: "lotv",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Multiple",
    name: "multiple",
    sortType: SortType.num,
  },
  {
    label: "Homeowner Equity",
    name: "homeowner_equity",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Option Percentage",
    name: "option_percentage",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Risk Adjustment",
    label2: "Percentage",
    name: "risk_adjustment_percentage",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Max IRR",
    name: "max_irr",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Cap Promotion %",
    name: "cap_promotion_percent",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Cap Promotion Term (Months)",
    name: "cap_promotion_term",
    sortType: SortType.num,
  },
  {
    label: "Gross Uncapped HIEV",
    name: "gross_uncapped_heiv",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Gross Capped HIEV",
    name: "gross_capped_heiv",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Net HIEV",
    name: "net_heiv",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "3rd Party Valuation",
    name: "third_party_valuation",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Zip",
    name: "zip",
    sortType: SortType.num,
  },
  {
    label: "City",
    name: "city",
    sortType: SortType.alphabet,
  },
  {
    label: "State",
    name: "state",
    sortType: SortType.alphabet,
  },
  {
    label: "FICO",
    name: "fico",
    sortType: SortType.alphabet,
  },
  {
    label: "Lien Position",
    name: "lien_position",
    sortType: SortType.alphabet,
  },
  {
    label: "Senior Liens",
    name: "senior_liens",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "1st Lien",
    name: "first_lien",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "2nd Lien",
    name: "second_lien",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Security Interest",
    label2: "Recording Number",
    name: "security_interest_recording_number",
  },
  {
    label: "Assignment",
    label2: "Recording Number",
    name: "assignment_recording_number",
    sortType: SortType.alphabet,
  },
  {
    label: "Impaired",
    name: "impaired",
    sortType: SortType.alphabet,
  },
];

export const columnsPledgeList = [
  {
    label: "Investment ID",
    name: "investment_id",
    sortType: SortType.alphabet,
  },
  {
    label: "Investment Amount",
    name: "investment_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Investment Term",
    name: "investment_term",
    sortType: SortType.num,
    filterType: FilterType.select,
    filterSelectOptions: INVESTMENT_TERM_FILTER,
  },
  {
    label: "Effective Date",
    name: "effective_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Closing Date",
    name: "closing_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Pledge Date",
    name: "pledged_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Purchase Price",
    name: "purchase_price",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Appraised Value",
    name: "appraised_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "OTV",
    name: "otv",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "LOTV",
    name: "lotv",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Multiple",
    name: "multiple",
    sortType: SortType.num,
  },
  {
    label: "Homeowner Equity",
    name: "homeowner_equity",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Option Percentage",
    name: "option_percentage",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Risk Adjustment",
    label2: "Percentage",
    name: "risk_adjustment_percentage",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Max IRR",
    name: "max_irr",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Cap Promotion %",
    name: "cap_promotion_percent",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Cap Promotion",
    name: "cap_promotion_term",
    sortType: SortType.num,
  },
  {
    label: "Gross Uncapped HIEV",
    name: "gross_uncapped_heiv",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Gross Capped HIEV",
    name: "gross_capped_heiv",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Net HIEV",
    name: "net_heiv",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "3rd Party Valuation",
    name: "third_party_valuation",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Zip",
    name: "zip",
    sortType: SortType.num,
  },
  {
    label: "City",
    name: "city",
    sortType: SortType.alphabet,
  },
  {
    label: "State",
    name: "state",
    sortType: SortType.alphabet,
  },
  {
    label: "FICO",
    name: "fico",
    sortType: SortType.alphabet,
  },
  {
    label: "Lien Position",
    name: "lien_position",
    sortType: SortType.alphabet,
  },
  {
    label: "Senior Liens",
    name: "senior_liens",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "1st Lien",
    name: "first_lien",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "2nd Lien",
    name: "second_lien",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Security Interest",
    label2: "Recording Number",
    name: "security_interest_recording_number",
  },
  {
    label: "Assignment",
    label2: "Recording Number",
    name: "assignment_recording_number",
    sortType: SortType.alphabet,
  },
  {
    label: "Impaired",
    name: "impaired",
    sortType: SortType.alphabet,
  },
];

export const columnsOfficialRunSelectionAssetList = [
  {
    label: "Run ID",
    name: "model_run_id",
  },
  {
    label: "Asset List Time",
    name: "valuation_date",
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Contract Count",
    name: "number_of_contracts",
  },
  {
    label: "Option Amount",
    name: "option_amount",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Purchase Price",
    name: "purchase_price",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Acquisition Fee",
    name: "acquisition_fee",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
];

export const columnsOfficialRunSelectionValuationList = [
  {
    label: "Run ID",
    name: "model_run_id",
  },
  {
    label: "Asset List Time",
    name: "valuation_date",
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Contract Count",
    name: "number_of_contracts",
  },
  {
    label: "Option Amount",
    name: "option_amount",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Purchase Price",
    name: "purchase_price",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Discout Rate",
    name: "discount_rate",
    filterType: FilterType.percentNotDecimal,
    formatType: FormatType.percentNotDecimal,
  },
  {
    label: "Internal Valuation",
    label2: "Low",
    name: "value_low",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Internal Valuation",
    label2: "Mid",
    name: "value_mid",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Internal Valuation",
    label2: "High",
    name: "value_high",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "HIEV",
    name: "hiev",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
];

export const manageUsersColumns = [
  {
    label: "Name",
    name: "name",
    sortType: SortType.alphabet,
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Phone",
    name: "phone_number",
  },
  {
    label: "Role",
    name: "role",
    sortType: SortType.alphabet,
  },
];

export const permissionsColumns = [
  {
    label: "Name",
    name: "name",
    sortType: SortType.alphabet,
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Role",
    name: "role",
    sortType: SortType.alphabet,
  },
];

export const historyColumns = [
  {
    label: "Date",
    name: "dateFull",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
  },
  {
    label: "User",
    name: "user",
    sortType: SortType.alphabet,
    filterType: FilterType.checkboxList,
    getOptions: "users",
  },
  {
    label: "Functionality",
    name: "type",
    sortType: SortType.alphabet,
    className: "whiteSpaceNormal",
  },
  {
    label: "Action Type",
    name: "action_type",
    sortType: SortType.alphabet,
    filterType: FilterType.checkboxList,
    filterSelectOptions: actionTypesHistory,
  },
  {
    label: "File / Description",
    name: "file",
    sortType: SortType.alphabet,
    className: "whiteSpaceNormal",
    formatType: FormatType.list,
  },
  {
    label: "Status",
    name: "status",
    sortType: SortType.alphabet,
    className: "status",
    filterType: FilterType.checkboxList,
    filterSelectOptions: statusHistory,
  },
];

export const columnsImparedList = [
  {
    label: "Contract ID",
    name: "contract_name",
    sortType: SortType.alphabet,
  },
  {
    label: "Filing Type",
    name: "filing_type",
    sortType: SortType.alphabet,
  },
  {
    label: "Date Funded",
    name: "date_funded",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "State",
    name: "state",
    sortType: SortType.alphabet,
  },
  {
    label: "Default Type",
    name: "default_type",
    sortType: SortType.alphabet,
  },
  {
    label: "Filing Date",
    name: "filing_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Notification Date",
    name: "notification_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Case Number",
    name: "case_number",
    sortType: SortType.alphabet,
  },
  {
    label: "Investment Amount",
    name: "investment_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Beginning Appraised Value",
    name: "beginning_appraised_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Senior Liens at Originator",
    name: "senior_liens_at_time",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Equity Percentage Origination",
    name: "equity_percentage_origination",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Equity at Origination",
    name: "equity_at_time",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "AVM Value",
    name: "avm_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Bid Due Date",
    name: "bid_due_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Foreclosure Date",
    name: "foreclosure_sale_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Bid Amount",
    name: "bid_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Appraisal at Foreclosuret",
    name: "appraisal_at_foreclosure",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Notes",
    name: "notes",
  },
  {
    label: "Distress Sale Discount",
    name: "distress_sale_discount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Foreclosure Costs",
    name: "foreclosure_costs",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Adjusted Appraisal",
    name: "adjusted_appraisal",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Appraisal Net Lien",
    name: "appraisal_net_lien",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Latest Valuation",
    name: "latest_valuation",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Excess Equity",
    name: "excess_equity",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Impair Current Month",
    name: "impair_current_month",
    sortType: SortType.alphabet,
  },
];

export const columnsPayoffsReport = [
  {
    label: "Contract ID",
    name: "contract_id",
    sortType: SortType.alphabet,
  },
  {
    label: "Line Name",
    name: "line_name",
    sortType: SortType.alphabet,
  },
  {
    label: "Address",
    name: "address",
    sortType: SortType.alphabet,
  },
  {
    label: "City",
    name: "city",
    sortType: SortType.alphabet,
  },
  {
    label: "State",
    name: "state",
    sortType: SortType.alphabet,
  },
  {
    label: "Zip",
    name: "zip",
    sortType: SortType.alphabet,
  },
  {
    label: "Effective Date",
    name: "effective_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Closing Date",
    name: "closing_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Investment Amount",
    name: "investment_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Purchase Price",
    name: "purchase_price",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Gross Uncapped HIEV",
    name: "gross_uncapped_heiv",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Gross Capped HIEV",
    name: "gross_capped_heiv",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Net HIEV",
    name: "net_heiv",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "3rd Party Valuation",
    name: "valuation_3rd",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Exit Date",
    name: "exit_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Payoff Requested Date",
    name: "payoff_requested_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Payoff Valid Date",
    name: "payoff_valid_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Appreciation Share",
    name: "appreciation_share",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Payoff Home Value",
    name: "payoff_home_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Payoff Amount",
    name: "payoff_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Impaired Status",
    name: "impaired_status",
    sortType: SortType.alphabet,
  },
  {
    label: "Payoff Status",
    name: "payoff_status",
    sortType: SortType.alphabet,
  },
  {
    label: "Property Status",
    name: "property_status",
    sortType: SortType.alphabet,
  },
];

export const columnsHPAContract = [
  {
    label: "Contract ID",
    name: "contract_id",
  },
  {
    label: "Pool Name",
    name: "pool_name",
  },
  {
    label: "Originator",
    name: "originator",
  },
  {
    label: "Effective Date",
    name: "effective_date",
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Exit Date",
    name: "exit_date",
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Metro Name",
    name: "metro_name",
  },
  {
    label: "State",
    name: "state",
  },
  {
    label: "City",
    name: "city",
  },
  {
    label: "Address",
    name: "address",
  },
  {
    label: "Zip",
    name: "zip",
  },
];

export const columnsHPAQuarterly = [
  {
    label: "Year",
    name: "year",
  },
  {
    label: "Quarter",
    name: "quarter",
  },
  {
    label: "Index",
    name: "index",
  },
];

export const columnsHPAEffectiveLatest = [
  {
    label: "HPI - Effective",
    name: "hpi_effective",
  },
  {
    label: "HPI - Latest",
    name: "hpi_latest",
  },
];

export const columnsHPAMetro = (is_metro: boolean) => [
  {
    label: "State",
    name: "state",
  },
  is_metro
    ? {
        label: "Metro",
        name: "metro",
      }
    : {
        label: "Non-Metro",
        name: "non_metro",
      },
  {
    label: "CBSA",
    name: "cbsa",
  },
  {
    label: "Year",
    name: "year",
  },
  {
    label: "Quarter",
    name: "quarter",
  },
  {
    label: "Index",
    name: "index",
  },
];

export const columnsForwardCashflows = [
  {
    label: "Contract ID",
    name: "contract_id",
  },
  {
    label: "Effective Date",
    name: "effective_date",
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Investment Term",
    name: "investment_term",
    sortType: SortType.num,
  },
  {
    label: "Option Amount",
    name: "option_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Appraisal at Origination",
    name: "appraisal_at_origination",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Risk Adjustment",
    name: "risk_adjustment",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Original Agreed Value",
    name: "original_agreed_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "HPA at Origination",
    name: "hpa_at_origination",
    sortType: SortType.num,
  },
  {
    label: "HPA at Valuation Date",
    name: "hpa_at_valuation_date",
    sortType: SortType.num,
  },
  {
    label: "Appraisal at Valuation Date",
    name: "appraisal_at_valuation_date",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Annualized Appreciation to Valuation Date",
    name: "annualized_appreciation_to_valuation_date",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Uncapped Intrinsic at Valuation Date",
    name: "uncapped_intrinsic_at_valuation_date",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Capped Intrinsic at Valuation Date",
    name: "capped_intrinsic_at_valuation_date",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Intrinsic at Valuation Date",
    name: "intrinsic_at_valuation_date",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Gross IRR at Valuation Date",
    name: "gross_irr_at_valuation_date",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Expected Net IRR at Origination",
    name: "expected_net_irr_at_origination",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Forward Net IRR at Valuation Date",
    name: "forward_net_irr_at_valuation_date",
    sortType: SortType.num,
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
];

export const columnsGrossMonthlyReturns = [
  {
    label: "Month",
    name: "month",
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Constant Contracts - ",
    label2: "Previous Month Valuation",
    name: "prev_month_val",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
    notEmpty: true,
  },
  {
    label: "Constant Contracts - ",
    label2: "Current Month Valuation",
    name: "cur_month_val",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
    notEmpty: true,
  },
  {
    label: "New Purchases",
    label2: "at Acquisition Cost",
    name: "purchases_acquisition_option",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
    notEmpty: true,
  },
  {
    label: "New Purchases",
    label2: "at Valuation",
    name: "new_purchases_month",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
    notEmpty: true,
  },
  {
    label: "Exits",
    label2: "Prior Month Valuation",
    name: "exits_month_valuation",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
    notEmpty: true,
  },
  {
    label: "Exits Actual Gross",
    label2: "Repayment Amount",
    name: "exits_gross_repayment",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
    notEmpty: true,
  },
  {
    label: "Constant Contracts",
    label2: "% Change Valuation",
    name: "constant_contracts_val",
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "New Purchases",
    label2: "% Change Valuation",
    name: "purchases_change_val",
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Exits",
    label2: "% Change Valuation",
    name: "exits_change_val",
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "Constant Contracts",
    label2: "$ Change in Valuation",
    name: "constant_contracts_in_val",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
    notEmpty: true,
  },
  {
    label: "New Purchases",
    label2: "$ Change in Valuation",
    name: "purchases_change_in_val",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
    notEmpty: true,
  },
  {
    label: "Exits",
    label2: "$ Change in Valuation",
    name: "exit_change_in_val",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
    notEmpty: true,
  },
  {
    label: "Constant Contract Count",
    name: "constant_contract_count",
  },
  {
    label: "New Purchases Count",
    name: "new_purchases_count",
  },
  {
    label: "Exits Count",
    name: "exits_count",
  },
  {
    label: "Weighted Average",
    label2: "% Monthly Change in Valuation",
    name: "weighted_average_in_val",
    filterType: FilterType.percent,
    formatType: FormatType.percent,
  },
  {
    label: "$ Monthly Change",
    label2: "in Valuation",
    name: "monthly_change_in_val",
    filterType: FilterType.amount,
    formatType: FormatType.amount,
    notEmpty: true,
  },
];

export const columnsForeclosureStatusReport = [
  {
    label: "Contract ID",
    name: "contract_id",
    sortType: SortType.alphabet,
  },
  {
    label: "Investment Amount",
    name: "investment_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Date Funded",
    name: "date_funded",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "State",
    name: "state",
    sortType: SortType.alphabet,
  },
  {
    label: "Filing Type",
    name: "filing_type",
    sortType: SortType.alphabet,
  },
  {
    label: "Default Type",
    name: "default_type",
    sortType: SortType.alphabet,
  },
  {
    label: "Notification Date",
    name: "notification_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Beginning Appraised Value",
    name: "beginning_appraised_value",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Foreclosure Sale Date",
    name: "foreclosure_sale_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Bid Due Date",
    name: "bid_due_date",
    sortType: SortType.alphabet,
    filterType: FilterType.date,
    formatType: FormatType.date,
  },
  {
    label: "Bid Amount",
    name: "bid_amount",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Appraisal at Foreclosure",
    name: "appraisal_at_foreclosure",
    sortType: SortType.num,
    filterType: FilterType.amount,
    formatType: FormatType.amount,
  },
  {
    label: "Notes",
    name: "notes",
    sortType: SortType.alphabet,
  },
];
