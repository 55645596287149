import { CustomDatePicker, Input } from "components/common";
import s from "./UpdateContract.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/reduxHooks";
import { FormUpdateDataButtons } from "../FormUpdateDataButtons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  formatAmountToNumber,
  getInitialAmount,
  getValueChangeInputAmount,
} from "utils";
import moment from "moment";
import { FieldWrapper } from "components/common/FormElements/FieldWrapper";
import { UpdateContractDataSecondType } from "store/types/UpdateContractType";
import {
  getUpdateContractData,
  updateContractData,
} from "store/actions/UpdateContractActions";
import { resetData } from "store/reducers/UpdateContractSlice";
import { FiltersBlock } from "../../FiltersBlock/FiltersBlock";
import { FiltersRow } from "../../FiltersBlock/FiltersRow";
import { FiltersField } from "../../FiltersBlock/FiltersField";
import { usePrevious } from "hooks/usePrevious";

type Props = {
  data: UpdateContractDataSecondType;
  isLoading: boolean;
  isUpdated: boolean;
};

interface FormValues {
  contract_id: string;
  effective_date: Date | null;
  secondary_purchase_date: Date | null;
  option_amount: string;
  secondary_acquisition_fee: string;
  secondary_assignment_cost: string;
  secondary_tax_title_cost: string;
  secondary_purchase_price: string;
}

export const FormSecondary = ({ data, isLoading, isUpdated }: Props) => {
  const [fetchData, setFetchData] = useState<any>(null);
  const [shoudKeepDirtyValues, setShoudKeepDirtyValues] = useState<any>(true);

  const dispatch = useAppDispatch();

  const withSubmitModal = true;
  const idForm = "updateContractSecondary";

  const prevContractId = usePrevious(data.contract_id);

  const getPurchasePrice = () => {
    return (
      (data.option_amount || 0) +
      (data.secondary_acquisition_fee || 0) +
      (data.secondary_assignment_cost || 0) +
      (data.secondary_tax_title_cost || 0)
    );
  };

  const getInitialData = (data: UpdateContractDataSecondType) => {
    return {
      contract_id: data.contract_id,
      effective_date: data.effective_date
        ? moment(data.effective_date).toDate()
        : null,
      secondary_purchase_date: data.secondary_purchase_date
        ? moment(data.secondary_purchase_date).toDate()
        : null,
      option_amount: getInitialAmount(data.option_amount),
      secondary_acquisition_fee: getInitialAmount(
        data.secondary_acquisition_fee
      ),
      secondary_assignment_cost: getInitialAmount(
        data.secondary_assignment_cost
      ),
      secondary_tax_title_cost: getInitialAmount(data.secondary_tax_title_cost),
      secondary_purchase_price: getInitialAmount(getPurchasePrice()),
    };
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    reset,
    watch,
  } = useForm<FormValues>({
    shouldFocusError: false,
    defaultValues: getInitialData(data),
  });

  const watch1 = watch("option_amount");
  const watch2 = watch("secondary_acquisition_fee");
  const watch3 = watch("secondary_assignment_cost");
  const watch4 = watch("secondary_tax_title_cost");

  useEffect(() => {
    setValue(
      "secondary_purchase_price",
      getInitialAmount(
        (formatAmountToNumber(watch1) || 0) +
          (formatAmountToNumber(watch2) || 0) +
          (formatAmountToNumber(watch3) || 0) +
          (formatAmountToNumber(watch4) || 0)
      )
    );
  }, [watch1, watch2, watch3, watch4]);

  useEffect(() => {
    if (
      prevContractId &&
      data.contract_id &&
      prevContractId !== data.contract_id
    ) {
      reset(getInitialData(data));
      return;
    }

    if (data.contract_id) {
      reset(getInitialData(data), { keepDirtyValues: shoudKeepDirtyValues });
      !shoudKeepDirtyValues && setShoudKeepDirtyValues(true);
    }
  }, [data]);

  const onCancel = () => {
    dispatch(resetData());
  };

  const onChangeInputAmount = (name: keyof FormValues, value: string) => {
    const newVal = getValueChangeInputAmount(value);
    setValue(name, newVal);
  };

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const {
      effective_date,
      secondary_purchase_date,
      option_amount,
      secondary_acquisition_fee,
      secondary_assignment_cost,
      secondary_tax_title_cost,
      secondary_purchase_price,
      ...rest
    } = formData;

    const fetchData = {
      ...rest,
      effective_date: effective_date
        ? moment(effective_date).format("MM/DD/YYYY")
        : "",
      secondary_purchase_date: secondary_purchase_date
        ? moment(secondary_purchase_date).format("MM/DD/YYYY")
        : "",
      option_amount: formatAmountToNumber(option_amount),
      secondary_acquisition_fee: formatAmountToNumber(
        secondary_acquisition_fee
      ),
      secondary_assignment_cost: formatAmountToNumber(
        secondary_assignment_cost
      ),
      secondary_tax_title_cost: formatAmountToNumber(secondary_tax_title_cost),
      secondary_purchase_price: formatAmountToNumber(secondary_purchase_price),
    };

    setShoudKeepDirtyValues(false);

    withSubmitModal
      ? setFetchData(fetchData)
      : dispatch(updateContractData(fetchData));
  };

  return (
    <>
      <FiltersBlock
        className={s.second}
        title="Secondary Purchase Details"
        idForm={idForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FiltersRow>
          <FiltersField label="Effective Date">
            <Controller
              control={control}
              name="effective_date"
              render={({ field }) => (
                <FieldWrapper error={errors.effective_date}>
                  <CustomDatePicker
                    {...field}
                    startDate={field.value}
                    setStartDate={field.onChange}
                    disabled
                  />
                </FieldWrapper>
              )}
            />
          </FiltersField>
          <FiltersField label="Secondary Closing Date">
            <Controller
              control={control}
              name="secondary_purchase_date"
              render={({ field }) => (
                <FieldWrapper error={errors.secondary_purchase_date}>
                  <CustomDatePicker
                    {...field}
                    startDate={field.value}
                    setStartDate={field.onChange}
                    placement="right"
                  />
                </FieldWrapper>
              )}
            />
          </FiltersField>
          <FiltersField label="Option Amount">
            <Controller
              control={control}
              name="option_amount"
              render={({ field }) => (
                <FieldWrapper error={errors.option_amount}>
                  <Input
                    {...field}
                    onChangeCustom={onChangeInputAmount}
                    leftSymbol="$"
                    amountFormat
                  />
                </FieldWrapper>
              )}
            />
          </FiltersField>
        </FiltersRow>
        <FiltersRow>
          <></>
          <FiltersField label="Secondary Acquisition Fee">
            <Controller
              control={control}
              name="secondary_acquisition_fee"
              render={({ field }) => (
                <FieldWrapper error={errors.secondary_acquisition_fee}>
                  <Input
                    {...field}
                    onChangeCustom={onChangeInputAmount}
                    leftSymbol="$"
                    amountFormat
                  />
                </FieldWrapper>
              )}
            />
          </FiltersField>
          <FiltersField label="Secondary Assignment Cost">
            <Controller
              control={control}
              name="secondary_assignment_cost"
              render={({ field }) => (
                <FieldWrapper error={errors.secondary_assignment_cost}>
                  <Input
                    {...field}
                    onChangeCustom={onChangeInputAmount}
                    leftSymbol="$"
                    amountFormat
                  />
                </FieldWrapper>
              )}
            />
          </FiltersField>
        </FiltersRow>
        <FiltersRow>
          <FiltersField label="Secondary Tax Title Cost">
            <Controller
              control={control}
              name="secondary_tax_title_cost"
              render={({ field }) => (
                <FieldWrapper error={errors.secondary_tax_title_cost}>
                  <Input
                    {...field}
                    onChangeCustom={onChangeInputAmount}
                    leftSymbol="$"
                    amountFormat
                  />
                </FieldWrapper>
              )}
            />
          </FiltersField>
          <FiltersField label="Secondary Purchase Price">
            <Controller
              control={control}
              name="secondary_purchase_price"
              render={({ field }) => (
                <FieldWrapper error={errors.secondary_purchase_price}>
                  <Input
                    {...field}
                    onChangeCustom={onChangeInputAmount}
                    leftSymbol="$"
                    amountFormat
                    disabled
                  />
                </FieldWrapper>
              )}
            />
          </FiltersField>
        </FiltersRow>
        <FormUpdateDataButtons
          disabled={!isDirty}
          withSubmitModal={withSubmitModal}
          withCancelModal
          onCancel={onCancel}
          idForm={idForm}
          fetchData={fetchData}
          fetchDataAction={updateContractData}
          updateDataAction={() =>
            getUpdateContractData({ contract_id: data.contract_id })
          }
          isUpdated={isUpdated}
          isLoading={isLoading}
          margin="16px 0 0"
        />
      </FiltersBlock>
    </>
  );
};
