import { Navigate, Outlet } from "react-router-dom";

import {
  AccountingRoute,
  AuthRoute,
  DashboardsRoute,
  DefaultRoute,
  HistoryRoute,
  ImportsRoute,
  KasfValuationModelRoute,
  ManageUsersRoute,
  NotFoundRoute,
  PermissionsRoute,
  PledgeCollateralRoute,
  RecordManagement,
  SettingsRoute,
  WatchlistRoute,
} from "const/routes";
import { Watchlist } from "components/pages/Watchlist/Watchlist";
import { Settings } from "components/pages/Settings/Settings";
import { Login } from "components/pages/Auth/Login";
import { ForgotPassword } from "components/pages/Auth/ForgotPassword";
import { ForgotPasswordConfirmation } from "components/pages/Auth/ForgotPasswordConfirmation";
import { ManageUsers } from "components/pages/ManageUsers/ManageUsers";
import { HistoryPage } from "components/pages/History/HistoryPage";
import { UpdateUser } from "components/pages/ManageUsers/UpdateUser";
import {
  DashboardAllMSASHPA,
  DashboardExit,
  DashboardPNT20232,
  DashboardPledgeCollateral,
  DashboardPointBuyBox,
  DashboardPortfolio,
  DashboardSecuritization,
  DashboardAcquiredPortfolio,
} from "components/pages/Dashboards";
import {
  OON,
  Detail,
  Tape,
  PreliminaryExits,
  OriginationFeesSweep,
  MonthlyCollections,
  ThirdPartyReports,
  FHFA,
  DefaultMonitoring,
  TitlePolicyImport,
  DemographicsDataImport,
} from "components/pages/Imports";
import {
  AssetList,
  OfficialRunSelection,
  InternalValuation,
  ThirdPartyComparisionReport,
  InternalValuationComparisonReport,
  HPAMetro,
} from "components/pages/KasfValuation";
import {
  PledgeDebtLine,
  PledgeList,
  SmmResolutionRates,
} from "components/pages/PledgeCollateral";
import {
  ExitsVarianceReport,
  ActualReturnsReport,
  ImparedList,
  MonthlyRolls,
  PayoffsReport,
} from "components/pages/Accounting";
import {
  DeleteContractDetails,
  LookUpContractDetails,
  PayoffDemand,
  PreliminaryExit,
  UpdateContractDetails,
} from "components/pages/RecordManagement";
import { HPAContract } from "components/pages/KasfValuation/HPAContract";
import { ForwardCashflows } from "./components/pages/KasfValuation/ForwardCashFlows/ForwardCashFlows";
import { ResetPasswordSuccess } from "./components/pages/Auth/ResetPasswordSuccess";
import { Permissions } from "components/pages/Permissions/Permissions";
import { NotFound } from "components/pages/NotFound/NotFound";
import { GrossMonthlyReturns } from "./components/pages/Accounting/GrossMonthlyReturns";
import { ForeclosureStatusReport } from "components/pages/Accounting/ForeclosureStatusReport";

export const PRIVATE_ROUTES = [
  {
    id: 0,
    path: "/",
    element: <Navigate to={DefaultRoute} replace />,
  },
  {
    id: 1,
    path: WatchlistRoute,
    element: <Watchlist />,
  },
  {
    id: 2,
    path: DashboardsRoute.ROOT,
    element: <Outlet />,
    subRoutes: [
      {
        id: 1,
        path: DashboardsRoute.SECURITIZATION,
        element: <DashboardSecuritization />,
      },
      {
        id: 2,
        path: DashboardsRoute.PORTFOLIO,
        element: <DashboardPortfolio />,
      },
      {
        id: 3,
        path: DashboardsRoute.ALL_MSAS_HPA,
        element: <DashboardAllMSASHPA />,
      },
      {
        id: 4,
        path: DashboardsRoute.ACQUIRED_PORTFOLIO,
        element: <DashboardAcquiredPortfolio />,
      },
      {
        id: 5,
        path: DashboardsRoute.POINT_BUY_BOX,
        element: <DashboardPointBuyBox />,
      },
      {
        id: 6,
        path: DashboardsRoute.PNT2023_2,
        element: <DashboardPNT20232 />,
      },
      {
        id: 7,
        path: DashboardsRoute.PLEDGE_COLLATERAL,
        element: <DashboardPledgeCollateral />,
      },
      {
        id: 8,
        path: DashboardsRoute.EXIT,
        element: <DashboardExit />,
      },
    ],
  },
  {
    id: 3,
    path: ImportsRoute.ROOT,
    element: <Outlet />,
    subRoutes: [
      { id: 1, path: ImportsRoute.OON, element: <OON /> },
      {
        id: 2,
        path: ImportsRoute.DEFAULT_MONITORING,
        element: <DefaultMonitoring />,
      },
      { id: 2, path: ImportsRoute.DETAIL, element: <Detail /> },
      { id: 3, path: ImportsRoute.TAPE, element: <Tape /> },
      {
        id: 4,
        path: ImportsRoute.PRELIMINARY_EXITS,
        element: <PreliminaryExits />,
      },
      {
        id: 5,
        path: ImportsRoute.ORIGINATION_FEES_SWEEP,
        element: <OriginationFeesSweep />,
      },
      {
        id: 6,
        path: ImportsRoute.MONTHLY_COLLECTIONS,
        element: <MonthlyCollections />,
      },
      {
        id: 7,
        path: ImportsRoute.THIRD_PARTY_REPORTS,
        element: <ThirdPartyReports />,
      },
      { id: 8, path: ImportsRoute.FHFA, element: <FHFA /> },
      {
        id: 9,
        path: ImportsRoute.TITLE_POLICY,
        element: <TitlePolicyImport />,
      },
      {
        id: 10,
        path: ImportsRoute.DEMOGRAPHICS_DATA,
        element: <DemographicsDataImport />,
      },
    ],
  },
  {
    id: 4,
    path: KasfValuationModelRoute.ROOT,
    element: <Outlet />,
    subRoutes: [
      {
        id: 10,
        path: KasfValuationModelRoute.ASSET_LIST,
        element: <AssetList />,
      },
      {
        id: 11,
        path: KasfValuationModelRoute.FORWARD_CASHFLOWS,
        element: <ForwardCashflows />,
      },
      {
        id: 20,
        path: KasfValuationModelRoute.HPA_CONTRACT,
        element: <HPAContract />,
      },
      {
        id: 30,
        path: KasfValuationModelRoute.HPA_METRO,
        element: <HPAMetro />,
      },
      {
        id: 40,
        path: KasfValuationModelRoute.OFFICIAL_RUN_SELECTION,
        element: <OfficialRunSelection />,
      },
      {
        id: 50,
        path: KasfValuationModelRoute.KASF_INTERNAL_VALUATION,
        element: <InternalValuation />,
      },
      {
        id: 60,
        path: KasfValuationModelRoute.THIRD_PARTY_COMPARISON_REPORT,
        element: <ThirdPartyComparisionReport />,
      },
      {
        id: 70,
        path: KasfValuationModelRoute.INTERNAL_VALUATION_COMPARISON_REPORT,
        element: <InternalValuationComparisonReport />,
      },
    ],
  },
  {
    id: 5,
    path: AccountingRoute.ROOT,
    element: <Outlet />,
    subRoutes: [
      {
        id: 1,
        path: AccountingRoute.EXITS_VARIANCE_REPORT,
        element: <ExitsVarianceReport />,
      },
      {
        id: 2,
        path: AccountingRoute.ACTUAL_RETURNS_REPORT,
        element: <ActualReturnsReport />,
      },
      {
        id: 6,
        path: AccountingRoute.GROSS_MONTHLY_RETURNS,
        element: <GrossMonthlyReturns />,
      },
      {
        id: 7,
        path: AccountingRoute.FORECLOSURE_STATUS_REPORT,
        element: <ForeclosureStatusReport />,
      },
      {
        id: 3,
        path: AccountingRoute.MONTHLY_ROLLS,
        element: <MonthlyRolls />,
      },
      {
        id: 4,
        path: AccountingRoute.IMPARED_LIST,
        element: <ImparedList />,
      },
      {
        id: 5,
        path: AccountingRoute.PAYOFFS_REPORT,
        element: <PayoffsReport />,
      },
    ],
  },
  {
    id: 6,
    path: PledgeCollateralRoute.ROOT,
    element: <Outlet />,
    subRoutes: [
      {
        id: 1,
        path: PledgeCollateralRoute.PLEDGE_DEBT_LINE,
        element: <PledgeDebtLine />,
      },
      {
        id: 2,
        path: PledgeCollateralRoute.PLEDGE_LIST,
        element: <PledgeList />,
      },
      {
        id: 3,
        path: PledgeCollateralRoute.SMM_RESOLUTION_RATES,
        element: <SmmResolutionRates />,
      },
    ],
  },
  {
    id: 7,
    path: HistoryRoute,
    element: <HistoryPage />,
  },
  { id: 8, path: ManageUsersRoute.ROOT, element: <ManageUsers /> },
  { id: 9, path: ManageUsersRoute.ADD, element: <UpdateUser action="add" /> },
  {
    id: 10,
    path: `${ManageUsersRoute.EDIT}/:id`,
    element: <UpdateUser action="edit" />,
  },
  { id: 11, path: PermissionsRoute, element: <Permissions /> },
  {
    id: 13,
    path: RecordManagement.ROOT,
    element: <Outlet />,
    subRoutes: [
      {
        id: 1,
        path: RecordManagement.DELETE_CONTRACT_DETAILS,
        element: <DeleteContractDetails />,
      },
      {
        id: 2,
        path: RecordManagement.PAYOFF_DEMAND,
        element: <PayoffDemand />,
      },
      {
        id: 3,
        path: RecordManagement.PRELIMINARY_EXIT,
        element: <PreliminaryExit />,
      },
      {
        id: 4,
        path: RecordManagement.UPDATE_CONTRACT_DETAILS,
        element: <UpdateContractDetails />,
      },
      {
        id: 5,
        path: RecordManagement.LOOK_UP_CONTRACT_DETAILS,
        element: <LookUpContractDetails />,
      },
    ],
  },
];

export const AVAILABLE_PRIVATE_ROUTES = [
  { id: 1, path: SettingsRoute, element: <Settings /> },
  { id: 2, path: NotFoundRoute, element: <NotFound /> },
];

export const PUBLIC_ROUTES = [
  { id: 1, path: AuthRoute.LOGIN, element: <Login /> },
  { id: 2, path: AuthRoute.FORGOT_PASSWORD, element: <ForgotPassword /> },
  {
    id: 3,
    path: `${AuthRoute.FORGOT_PASSWORD_CONFIRMATION}/:accessCode`,
    element: <ForgotPasswordConfirmation />,
  },
  {
    id: 4,
    path: AuthRoute.RESET_PASSWORD_SUCCESS,
    element: <ResetPasswordSuccess />,
  },
];
