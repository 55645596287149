import { SelectReact } from "components/common/FormElements/SelectReact/SelectReact";
import s from "./FormBlock.module.scss";
import { Button } from "components/common";
import { useEffect, useState } from "react";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getSearchValues } from "store/actions/PayoffDemandActions";
import cn from "classnames";
import { usePrevious } from "hooks/usePrevious";

type Props = {
  getDataAction: any;
  contracts?: "contract_ids" | "preliminary_contracts" | "lookup_contracts";
  resetUpdated?: () => void;
  isHalfWidth?: boolean;
  isLoading: boolean;
  loadedContractId: string;
};

export const FormBlockSearchByContract = ({
  getDataAction,
  contracts = "contract_ids",
  resetUpdated,
  isHalfWidth,
  isLoading,
  loadedContractId,
}: Props) => {
  const { searchData } = useAppSelector((state) => state.payoffDemand);

  const [searchValue, setSearchValue] = useState("");

  const dispatch = useAppDispatch();

  const { valuesSelect, handleSelect, valuesSelectDirty, resetSelect } =
    useFormSelectArr({
      contract_id: null,
    });

  const { contract_id } = valuesSelect;

  const prevLoadedContractId = usePrevious(loadedContractId);

  useEffect(() => {
    dispatch(getSearchValues({}));
  }, []);

  useEffect(() => {
    if (prevLoadedContractId && !loadedContractId) resetSelect();
  }, [loadedContractId]);

  const getCurrentOptions = () => {
    const rowsCount = 100;
    const options = searchValue
      ? searchData[contracts]
          .filter((v) =>
            `${v}`.toLowerCase().includes(searchValue.toLowerCase())
          )
          .slice(0, rowsCount)
      : searchData[contracts].slice(0, rowsCount);

    const formattedOptions = options.map((i) => {
      return { value: i, label: i };
    });

    return loadedContractId
      ? [
          {
            value: loadedContractId,
            label: loadedContractId,
            isSelected: true,
          },
          ...formattedOptions,
        ]
      : formattedOptions;
  };

  const onSearch = () => {
    if (!isLoading) {
      resetUpdated && dispatch(resetUpdated());
      dispatch(getDataAction({ contract_id }));
    }
  };

  return (
    <div className={cn(s.wrapSearch, isHalfWidth && s.widthHalf)}>
      <span className={s.label}>Contract Id:</span>
      <div className={cn(s.searchRow, s.contract)}>
        <SelectReact
          name="contract_id"
          options={getCurrentOptions()}
          value={
            contract_id ? { value: contract_id, label: contract_id } : null
          }
          onSelect={(value: string) => handleSelect("contract_id", value)}
          onInputChange={(value: string) => setSearchValue(value)}
          isSearchable
          isClearable={false}
        />
        <Button
          onClick={onSearch}
          title="Search"
          wide="long"
          isLoading={isLoading}
          loader="..."
          disabled={
            !contract_id ||
            !valuesSelectDirty ||
            contract_id === loadedContractId
          }
        />
      </div>
    </div>
  );
};
