import { ReactNode } from "react";
import s from "./FiltersBlock.module.scss";

type Props = {
  label?: string;
  labelSmall?: string;
  children: ReactNode;
};

export const FiltersField = ({ label, labelSmall, children }: Props) => {
  return (
    <div className={s.field}>
      {label && <span className={s.label}>{label}</span>}
      {labelSmall && <span className={s.labelSmall}>{labelSmall}</span>}
      {children}
    </div>
  );
};
