import { createSlice } from "@reduxjs/toolkit";
import { getList } from "store/actions/ForeclosureStatusReportActions";
import { ForeclosureStatusReportType } from "store/types/ForeclosureStatusReportType";

const initialState = {
  isLoading: false,
  error: null,
  list: [],
} as ForeclosureStatusReportType;

export const ForeclosureStatusReportSlice = createSlice({
  name: "ForeclosureStatusList",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: {
    [getList.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
      state.error = null;
    },
    [getList.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getList.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError, resetList } = ForeclosureStatusReportSlice.actions;

export default ForeclosureStatusReportSlice.reducer;
