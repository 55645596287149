import { CustomDatePicker, Input } from "components/common";
import s from "./UpdateContract.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/reduxHooks";
import { FormUpdateDataButtons } from "../FormUpdateDataButtons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  formatAmountToNumber,
  getInitialAmount,
  getValueChangeInputAmount,
} from "utils";
import moment from "moment";
import { FieldWrapper } from "components/common/FormElements/FieldWrapper";
import { UpdateContractDataThirdType } from "store/types/UpdateContractType";
import {
  getUpdateContractData,
  updateContractData,
} from "store/actions/UpdateContractActions";
import { resetData } from "store/reducers/UpdateContractSlice";
import { FiltersBlock } from "../../FiltersBlock/FiltersBlock";
import { FiltersRow } from "../../FiltersBlock/FiltersRow";
import { FiltersField } from "../../FiltersBlock/FiltersField";
import { FiltersInnerBlock } from "components/modules/FiltersBlock/FiltersInnerBlock";
import { VALIDATE_DATE_NOT_LESS } from "const";
import { usePrevious } from "hooks/usePrevious";

type Props = {
  data: UpdateContractDataThirdType;
  isLoading: boolean;
  isUpdated: boolean;
};

interface FormValues {
  contract_id: string;
  foreclosure_sale_date: Date | null;
  bid_due_date: Date | null;
  bid_amount: string;
  appraisal_at_foreclosure: string;
  notes: string;
}

export const FormForeclosure = ({ data, isLoading, isUpdated }: Props) => {
  const [fetchData, setFetchData] = useState<any>(null);
  const [shoudKeepDirtyValues, setShoudKeepDirtyValues] = useState<any>(true);
  const dispatch = useAppDispatch();

  const withSubmitModal = true;
  const idForm = "updateContractForeclosure";

  const prevContractId = usePrevious(data.contract_id);

  const getInitialData = (data: UpdateContractDataThirdType) => {
    return {
      contract_id: data.contract_id,
      foreclosure_sale_date: data.foreclosure_sale_date
        ? moment(data.foreclosure_sale_date).toDate()
        : null,
      bid_due_date: data.bid_due_date
        ? moment(data.bid_due_date).toDate()
        : null,
      bid_amount: getInitialAmount(data.bid_amount),
      appraisal_at_foreclosure: getInitialAmount(data.appraisal_at_foreclosure),
      notes: data.notes ?? "",
    };
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    reset,
    watch,
  } = useForm<FormValues>({
    shouldFocusError: false,
    defaultValues: getInitialData(data),
  });

  const watch1 = watch("foreclosure_sale_date");

  useEffect(() => {
    if (
      prevContractId &&
      data.contract_id &&
      prevContractId !== data.contract_id
    ) {
      reset(getInitialData(data));
      return;
    }

    if (data.contract_id) {
      reset(getInitialData(data), { keepDirtyValues: shoudKeepDirtyValues });
      !shoudKeepDirtyValues && setShoudKeepDirtyValues(true);
    }
  }, [data]);

  const onCancel = () => {
    dispatch(resetData());
  };

  const onChangeInputAmount = (name: keyof FormValues, value: string) => {
    const newVal = getValueChangeInputAmount(value);
    setValue(name, newVal);
  };

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    const {
      foreclosure_sale_date,
      bid_due_date,
      bid_amount,
      appraisal_at_foreclosure,
      ...rest
    } = formData;

    const fetchData = {
      ...rest,
      foreclosure_sale_date: foreclosure_sale_date
        ? moment(foreclosure_sale_date).format("MM/DD/YYYY")
        : "",
      bid_due_date: bid_due_date
        ? moment(bid_due_date).format("MM/DD/YYYY")
        : "",
      bid_amount: formatAmountToNumber(bid_amount),
      appraisal_at_foreclosure: formatAmountToNumber(appraisal_at_foreclosure),
    };

    setShoudKeepDirtyValues(false);

    withSubmitModal
      ? setFetchData(fetchData)
      : dispatch(updateContractData(fetchData));
  };

  return (
    <>
      <FiltersBlock
        className={s.third}
        title="Foreclosure Details"
        idForm={idForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={s.thirdContainer}>
          <FiltersInnerBlock>
            <FiltersRow>
              <FiltersField label="Foreclosure Sale Date">
                <Controller
                  control={control}
                  name="foreclosure_sale_date"
                  render={({ field }) => (
                    <FieldWrapper error={errors.foreclosure_sale_date}>
                      <CustomDatePicker
                        {...field}
                        startDate={field.value}
                        setStartDate={field.onChange}
                        placement="right"
                      />
                    </FieldWrapper>
                  )}
                />
              </FiltersField>
              <FiltersField label="Bid Due Date">
                <Controller
                  control={control}
                  name="bid_due_date"
                  //@ts-ignore
                  rules={VALIDATE_DATE_NOT_LESS(watch1, "Sale Date")}
                  render={({ field }) => (
                    <FieldWrapper error={errors.bid_due_date}>
                      <CustomDatePicker
                        {...field}
                        startDate={field.value}
                        setStartDate={field.onChange}
                        placement="right"
                      />
                    </FieldWrapper>
                  )}
                />
              </FiltersField>
            </FiltersRow>
            <FiltersRow>
              <FiltersField label="Bid Amount">
                <Controller
                  control={control}
                  name="bid_amount"
                  render={({ field }) => (
                    <FieldWrapper error={errors.bid_amount}>
                      <Input
                        {...field}
                        onChangeCustom={onChangeInputAmount}
                        leftSymbol="$"
                        amountFormat
                      />
                    </FieldWrapper>
                  )}
                />
              </FiltersField>
              <FiltersField label="Appraisal at Foreclosure">
                <Controller
                  control={control}
                  name="appraisal_at_foreclosure"
                  render={({ field }) => (
                    <FieldWrapper error={errors.appraisal_at_foreclosure}>
                      <Input
                        {...field}
                        onChangeCustom={onChangeInputAmount}
                        leftSymbol="$"
                        amountFormat
                      />
                    </FieldWrapper>
                  )}
                />
              </FiltersField>
            </FiltersRow>
          </FiltersInnerBlock>
          <FiltersInnerBlock>
            <FiltersField label="Notes">
              <Controller
                control={control}
                name="notes"
                render={({ field }) => (
                  <FieldWrapper error={errors.notes}>
                    <textarea
                      {...field}
                      value={field.value}
                      className={s.textarea}
                    />
                  </FieldWrapper>
                )}
              />
            </FiltersField>
            <FormUpdateDataButtons
              disabled={!isDirty}
              withSubmitModal={withSubmitModal}
              withCancelModal
              onCancel={onCancel}
              idForm={idForm}
              fetchData={fetchData}
              fetchDataAction={updateContractData}
              updateDataAction={() =>
                getUpdateContractData({ contract_id: data.contract_id })
              }
              isUpdated={isUpdated}
              isLoading={isLoading}
              margin="16px 0 0"
            />
          </FiltersInnerBlock>
        </div>
      </FiltersBlock>
    </>
  );
};
