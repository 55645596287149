import { ReactNode } from "react";
import cn from "classnames";
import s from "./FiltersBlock.module.scss";

type Props = {
  children: ReactNode;
  title?: string;
  className?: string;
  idForm?: string;
  onSubmit?: () => void;
};

export const FiltersBlock = ({
  children,
  title,
  className,
  idForm,
  onSubmit,
}: Props) => {
  return (
    <>
      {idForm && onSubmit ? (
        <form
          className={cn(s.block, title && s.blockInner, className && className)}
          onSubmit={onSubmit}
          id={idForm}
        >
          {title && <h3>{title}</h3>}
          {children}
        </form>
      ) : (
        <div
          className={cn(s.block, title && s.blockInner, className && className)}
        >
          {title && <h3>{title}</h3>}
          {children}
        </div>
      )}
    </>
  );
};
