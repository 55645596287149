import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FieldsInfoBlocks } from "components/modules/FieldsInfoBlocks/FieldsInfoBlocks";
import { FormBlockSearchWithSelect } from "components/modules/FormBlock/FormBlockSearchWithSelect";
import { fieldsLookUpContract } from "const/fieldsInfoBlocks";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useGoBackInUpdateForm } from "hooks/useGoBackInUpdateForm";
import { useEffect } from "react";
import { getLookUpContractData } from "store/actions/LookUpContractActions";
import { resetData } from "store/reducers/LookUpContractSlice";
import { ListSkeleton } from "../../modules";

export const LookUpContractDetails = () => {
  const { data } = useAppSelector((state) => state.lookUpContract);
  const { isLoading } = useAppSelector((state) => state.lookUpContract);

  const isDataLoaded = Object.keys(data).length > 1;

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  const { secondScreenMode, onGoBack } = useGoBackInUpdateForm(
    data.origination_terms.contract_id,
    resetData
  );

  return (
    <PageContent
      title="Look Up Contract Details"
      path="Record Management"
      secondScreenMode={secondScreenMode}
      onGoBack={onGoBack}
    >
      <PageFormLayout widthLeft={100} rightContent={null} leftOnly>
        <div>
          <FormBlockSearchWithSelect getDataAction={getLookUpContractData} />
          {!isLoading ? (
            secondScreenMode &&
            data.origination_terms.contract_id && (
              <FieldsInfoBlocks blocks={fieldsLookUpContract} data={data} />
            )
          ) : (
            <ListSkeleton count={20}></ListSkeleton>
          )}
        </div>
      </PageFormLayout>
    </PageContent>
  );
};
