import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock } from "components/modules";
import { TableControlsWithSelectRows } from "components/modules/TableControls/TableControlsWithSelectRows";
import {
  DATE_FORMAT,
  DOWNLOAD_IMPAIRED,
  FILING_TYPE_OPTIONS,
  ORIGINATOR_OPTIONS_BASE,
  columnsImparedList,
} from "const";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useFormInputAmountArr } from "hooks/useFormInputAmountArr";
import { useFormInputPercentageArr } from "hooks/useFormInputPercentageArr";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { useFormSelectDateArr } from "hooks/useFormSelectDateArr";
import { usePrevious } from "hooks/usePrevious";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { cancelImpairedList, getList } from "store/actions/ImpairedListActions";
import { downloadResourceFromURL, encodeDataToUrl } from "utils";

export const ImparedList = () => {
  const { valuesSelect, handleSelect, valuesSelectDirty, updateInitialSelect } =
    useFormSelectArr({
      originator: "All",
      filingType: "All",
    });

  const {
    valuesInputPercent,
    handleChangeInputPercent,
    handleKeyDown,
    valuesInputPercentDirty,
    updateInitialInputPercent,
  } = useFormInputPercentageArr({ discount: "5%" });

  const dafaultCosts = 30000;

  const {
    valuesInputAmount,
    handleChangeInputAmount,
    valuesInputAmountDirty,
    updateInitialInputAmount,
  } = useFormInputAmountArr({
    costs: dafaultCosts.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  });

  const { valuesDate, handleDateChange, updateInitialDate } =
    useFormSelectDateArr({
      startDate: new Date(moment("01-01-2021").format(DATE_FORMAT)),
      endDate: null,
    });

  const { originator, filingType } = valuesSelect;

  const { discount } = valuesInputPercent;

  const { costs } = valuesInputAmount;

  const { startDate, endDate } = valuesDate;

  const [tableMode, setTableMode] = useState(false);

  const { list, isLoading } = useAppSelector((state) => state.imparedList);

  const dispatch = useAppDispatch();

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      !tableMode && setTableMode(true);
    }
  }, [isLoading]);

  const fieldsInPanel = {
    select1: {
      options: ORIGINATOR_OPTIONS_BASE,
      onSelect: (value: string) => handleSelect("originator", value),
      value: originator,
      fieldName: "Select Originator",
    },
    select2: {
      fieldName: "Select Filing Type",
      options: FILING_TYPE_OPTIONS,
      onSelect: (value: string) => handleSelect("filingType", value),
      value: filingType,
    },
    percent: {
      fieldName: "Distressed sale discount",
      value: discount,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleChangeInputPercent("discount", e.target.value),
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
        handleKeyDown("discount", discount, e),
    },
    amount: {
      fieldName: "Foreclosure costs",
      value: costs,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleChangeInputAmount("costs", e.target.value),
    },
  };

  const fields = {
    ...fieldsInPanel,
    startDate: {
      date: startDate,
      setDate: (value: Date | undefined | null) =>
        handleDateChange("startDate", value),
      fieldName: "Choose Filing Date",
      label: "Date From",
    },
    endDate: {
      date: endDate,
      setDate: (value: Date | undefined | null) =>
        handleDateChange("endDate", value),
      label: "Date To",
    },
  };

  const getTableList = () => {
    if (!isLoading) {
      dispatch(
        getList({
          originator: originator.toLocaleLowerCase(),
          filing_type: filingType.toLocaleLowerCase(),
          sale_discount: discount.replace("%", ""),
          foreclosure_costs: parseFloat(
            costs.replace(/[^0-9.]/g, "")
          ).toString(),
          filing_date_from: moment(startDate).format("YYYY-MM-DD"),
          filing_date_to: moment(endDate).format("YYYY-MM-DD"),
        })
      );

      updateInitialSelect();
      updateInitialInputPercent();
      updateInitialInputAmount();
      updateInitialDate();
    }
  };

  const onBackTableMode = () => setTableMode(false);

  const onDownloadFile = () => {
    const params = encodeDataToUrl({
      originator: originator.toLocaleLowerCase(),
      filing_type: filingType.toLocaleLowerCase(),
      sale_discount: discount.replace("%", ""),
      foreclosure_costs: parseFloat(costs.replace(/[^0-9.]/g, "")).toString(),
      filing_date_from: moment(startDate).format("YYYY-MM-DD"),
      filing_date_to: moment(endDate).format("YYYY-MM-DD"),
    });

    downloadResourceFromURL(
      `Impared List.xlsx`,
      `${DOWNLOAD_IMPAIRED}?${params}`
    );
  };

  const handleClickImportBtn = () => {
    getTableList();
  };

  const submitSelectedDataAction = async (
    selectedRows: string[],
    dateValue: string
  ) => {
    const dataArray = selectedRows.map((i) => {
      const valuesArr = i.split("---");
      return {
        contract_name: valuesArr[0],
        filing_type: valuesArr[1],
        cured_date: moment(dateValue).format("YYYY-MM-DD"),
      };
    });

    await dispatch(cancelImpairedList({ list_value: dataArray }));
    getTableList();
  };

  return (
    <PageContent
      title="Impaired List"
      path="Accounting"
      search={tableMode}
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout tableMode={tableMode}>
        <>
          <FormBlock
            fields={fields}
            importButton={{
              label: isLoading ? "Processing..." : "Generate Report",
              onclick: handleClickImportBtn,
              disabled: isLoading || !startDate || !endDate,
            }}
            tableMode={tableMode}
          />
          {tableMode && (
            <TableControlsWithSelectRows
              columnsList={columnsImparedList}
              list={list}
              isLoading={isLoading}
              defaultSortedColumn="date_funded"
              onDownloadFile={onDownloadFile}
              formInPanel={
                <FormBlock
                  fields={fieldsInPanel}
                  tableMode={true}
                  inControlPanel={true}
                  sizeFields={"120"}
                  importButton={{
                    label: "",
                    icon: "process",
                    onclick: handleClickImportBtn,
                    width: "45px",
                    disabled:
                      isLoading ||
                      (!valuesSelectDirty &&
                        !valuesInputPercentDirty &&
                        !valuesInputAmountDirty),
                  }}
                />
              }
              searchColumns={["contract_name"]}
              idRowSelector="custom_id"
              selectedActionBtnTitle="Update cured date"
              confirmModalText="Are you sure you want to update cured date?"
              confirmModalSubmitText="Update"
              confirmModalSubtitle="Please select the cured date: "
              submitSelectedDataAction={submitSelectedDataAction}
            />
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
