import s from "./ForwardCashFlows.module.scss";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Input, Select } from "components/common";
import { useFormInputPercentageArr } from "hooks/useFormInputPercentageArr";
import { FiltersRow } from "components/modules/FiltersBlock/FiltersRow";
import { FiltersField } from "components/modules/FiltersBlock/FiltersField";

export interface MonthRangedRate {
  startMonth: number;
  endMonth: number;
  rate: string;
}

export interface MonthRangedRateValues {
  range1: MonthRangedRate;
  range2: MonthRangedRate;
  range3: MonthRangedRate;
  range4: MonthRangedRate;
  range5: MonthRangedRate;
}

interface IProps {
  rangeName: string;
  value: MonthRangedRateValues;
  handleChange: (value: MonthRangedRateValues) => void;
}

const options = (start: number, end: number) =>
  Array.from({ length: end }, (value, index) => index)
    .filter((i: number) => i + 1 >= start)
    .map((i: number) => ({
      label: `Month ${i + 1}`,
      key: `${i + 1}`,
      value: `${i + 1}`,
    }));

export const AssumptionRange = ({ rangeName, value, handleChange }: IProps) => {
  const { valuesInputPercent, handleChangeInputPercent, handleKeyDown } =
    useFormInputPercentageArr({
      range1Rate: value.range1.rate,
      range2Rate: value.range2.rate,
      range3Rate: value.range3.rate,
      range4Rate: value.range4.rate,
      range5Rate: value.range5.rate,
    });

  const { range1Rate, range2Rate, range3Rate, range4Rate, range5Rate } =
    valuesInputPercent;

  const [range1Start, setRange1Start] = useState(value.range1.startMonth);
  const [range2Start, setRange2Start] = useState(value.range2.startMonth);
  const [range3Start, setRange3Start] = useState(value.range3.startMonth);
  const [range4Start, setRange4Start] = useState(value.range4.startMonth);
  const [range5Start, setRange5Start] = useState(value.range5.startMonth);
  const [range1End, setRange1End] = useState(value.range1.endMonth);
  const [range2End, setRange2End] = useState(value.range2.endMonth);
  const [range3End, setRange3End] = useState(value.range3.endMonth);
  const [range4End, setRange4End] = useState(value.range4.endMonth);
  const [range5End, setRange5End] = useState(value.range5.endMonth);

  const [range1EndOptions, setRange1EndOptions] = useState(
    options(value.range1.startMonth + 1, 352)
  );
  const [range2EndOptions, setRange2EndOptions] = useState(
    options(value.range2.startMonth + 1, 354)
  );
  const [range3EndOptions, setRange3EndOptions] = useState(
    options(value.range3.startMonth + 1, 356)
  );
  const [range4EndOptions, setRange4EndOptions] = useState(
    options(value.range4.startMonth + 1, 358)
  );
  const [range5EndOptions, setRange5EndOptions] = useState(
    options(value.range5.startMonth + 1, 360)
  );

  const setStartMonth = (index: number, month: number) => {
    switch (index) {
      case 1:
        setRange1Start(month);
        break;
      case 2:
        setRange2Start(month);
        break;
      case 3:
        setRange3Start(month);
        break;
      case 4:
        setRange4Start(month);
        break;
      case 5:
        setRange5Start(month);
        break;
      default:
        break;
    }
  };

  const setEndMonth = (index: number, month: number) => {
    switch (index) {
      case 1:
        setRange1End(month);
        setRange2Start(month + 1);
        setRange2EndOptions(options(month + 2, 354));
        break;
      case 2:
        setRange2End(month);
        setRange3Start(month + 1);
        setRange3EndOptions(options(month + 2, 356));
        break;
      case 3:
        setRange3End(month);
        setRange4Start(month + 1);
        setRange4EndOptions(options(month + 2, 358));
        break;
      case 4:
        setRange4End(month);
        setRange5Start(month + 1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleChange({
      range1: {
        startMonth: range1Start,
        endMonth: range1End,
        rate: range1Rate,
      },
      range2: {
        startMonth: range2Start,
        endMonth: range2End,
        rate: range2Rate,
      },
      range3: {
        startMonth: range3Start,
        endMonth: range3End,
        rate: range3Rate,
      },
      range4: {
        startMonth: range4Start,
        endMonth: range4End,
        rate: range4Rate,
      },
      range5: {
        startMonth: range5Start,
        endMonth: range5End,
        rate: range5Rate,
      },
    });
  }, [
    range1Start,
    range2Start,
    range3Start,
    range4Start,
    range5Start,
    range1End,
    range2End,
    range3End,
    range4End,
    range5End,
    range1Rate,
    range2Rate,
    range3Rate,
    range4Rate,
    range5Rate,
  ]);

  return (
    <>
      <div className={s.rangeRow}>
        <span className={s.label}>{`${rangeName} Range 1`}</span>
        <FiltersRow isNarrow className={s.filterRowNarrow}>
          <FiltersField>
            <Select
              options={options(1, 351)}
              onSelect={(v) => setStartMonth(1, +v)}
              value={`${range1Start}`}
              disabled={true}
            />
          </FiltersField>
          <FiltersField>
            <Select
              options={range1EndOptions}
              onSelect={(v) => setEndMonth(1, +v)}
              value={`${range1End}`}
            />
          </FiltersField>
          <div className={s.fieldSm}>
            <Input
              value={range1Rate}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputPercent("range1Rate", e.target.value)
              }
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyDown("range1Rate", range1Rate, e)
              }
            />
          </div>
        </FiltersRow>
      </div>
      <div className={s.rangeRow}>
        <span className={s.label}>{`${rangeName} Range 2`}</span>
        <FiltersRow isNarrow className={s.filterRowNarrow}>
          <FiltersField>
            <Select
              options={options(3, 353)}
              onSelect={(v) => setStartMonth(2, +v)}
              value={`${range2Start}`}
              disabled={true}
            />
          </FiltersField>
          <FiltersField>
            <Select
              options={range2EndOptions}
              onSelect={(v) => setEndMonth(2, +v)}
              value={`${range2End}`}
            />
          </FiltersField>
          <div className={s.fieldSm}>
            <Input
              value={range2Rate}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputPercent("range2Rate", e.target.value)
              }
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyDown("range2Rate", range2Rate, e)
              }
            />
          </div>
        </FiltersRow>
      </div>
      <div className={s.rangeRow}>
        <span className={s.label}>{`${rangeName} Range 3`}</span>
        <FiltersRow isNarrow className={s.filterRowNarrow}>
          <FiltersField>
            <Select
              options={options(5, 355)}
              onSelect={(v) => setStartMonth(3, +v)}
              value={`${range3Start}`}
              disabled={true}
            />
          </FiltersField>
          <FiltersField>
            <Select
              options={range3EndOptions}
              onSelect={(v) => setEndMonth(3, +v)}
              value={`${range3End}`}
            />
          </FiltersField>
          <div className={s.fieldSm}>
            <Input
              value={range3Rate}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputPercent("range3Rate", e.target.value)
              }
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyDown("range3Rate", range3Rate, e)
              }
            />
          </div>
        </FiltersRow>
      </div>
      <div className={s.rangeRow}>
        <span className={s.label}>{`${rangeName} Range 4`}</span>
        <FiltersRow isNarrow className={s.filterRowNarrow}>
          <FiltersField>
            <Select
              options={options(7, 357)}
              onSelect={(v) => setStartMonth(4, +v)}
              value={`${range4Start}`}
              disabled={true}
            />
          </FiltersField>
          <FiltersField>
            <Select
              options={range4EndOptions}
              onSelect={(v) => setEndMonth(4, +v)}
              value={`${range4End}`}
            />
          </FiltersField>
          <div className={s.fieldSm}>
            <Input
              value={range4Rate}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputPercent("range4Rate", e.target.value)
              }
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyDown("range4Rate", range4Rate, e)
              }
            />
          </div>
        </FiltersRow>
      </div>
      <div className={s.rangeRow}>
        <span className={s.label}>{`${rangeName} Range 5`}</span>
        <FiltersRow isNarrow className={s.filterRowNarrow}>
          <FiltersField>
            <Select
              options={options(9, 360)}
              onSelect={(v) => setStartMonth(5, +v)}
              value={`${range5Start}`}
              disabled={true}
            />
          </FiltersField>
          <FiltersField>
            <Select
              options={range5EndOptions}
              onSelect={(v) => setEndMonth(5, +v)}
              value={`${range5End}`}
              disabled={true}
            />
          </FiltersField>
          <div className={s.fieldSm}>
            <Input
              value={range5Rate}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputPercent("range5Rate", e.target.value)
              }
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyDown("range5Rate", range5Rate, e)
              }
            />
          </div>
        </FiltersRow>
      </div>
    </>
  );
};
