import { createSlice } from "@reduxjs/toolkit";
import {
  getUpdateContractData,
  updateContractData,
} from "store/actions/UpdateContractActions";

import { UpdateContractType } from "store/types/UpdateContractType";

const initialState = {
  isLoading: false,
  error: null,
  data: {},
  isUpdated: false,
} as UpdateContractType;

export const UpdateContractSlice = createSlice({
  name: "updateContract",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetData: (state) => {
      state.data = {} as UpdateContractType["data"];
    },
    resetUpdated: (state) => {
      state.isUpdated = false;
    },
  },
  extraReducers: {
    [getUpdateContractData.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    [getUpdateContractData.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getUpdateContractData.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [updateContractData.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.isUpdated = true;
      state.error = null;
    },
    [updateContractData.pending.type]: (state) => {
      state.isUpdated = false;
      state.isLoading = true;
    },
    [updateContractData.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setError, resetData, resetUpdated } =
  UpdateContractSlice.actions;

export default UpdateContractSlice.reducer;
