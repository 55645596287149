import { ReactNode } from "react";
import cn from "classnames";
import s from "./FiltersBlock.module.scss";

type Props = {
  children: ReactNode;
  isNarrow?: boolean;
  className?: string;
};

export const FiltersRow = ({ children, isNarrow, className }: Props) => {
  return (
    <div className={cn(isNarrow ? s.rowNarrow : s.row, className && className)}>
      {children}
    </div>
  );
};
