import { PageContent } from "components/layout/PageContent/PageContent";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock, TableControls } from "components/modules";
import {
  DATE_FORMAT,
  ORIGINATOR_OPTIONS_BASE,
  DOWNLOAD_FORECLOSURE_STATUS,
  columnsForeclosureStatusReport,
} from "const";
import { useAppSelector, useAppDispatch } from "hooks/reduxHooks";
import { useFormSelectArr } from "hooks/useFormSelectArr";
import { useFormSelectDateArr } from "hooks/useFormSelectDateArr";
import { usePrevious } from "hooks/usePrevious";
import moment from "moment";
import { useState, useEffect } from "react";
import { getList } from "store/actions/ForeclosureStatusReportActions";
import { encodeDataToUrl, downloadResourceFromURL } from "utils";

type FormValues = {
  originator: string;
  startDate: string;
  endDate: string;
  startDate2: string;
  endDate2: string;
};

export const ForeclosureStatusReport = () => {
  const { valuesSelect, handleSelect, valuesSelectDirty, updateInitialSelect } =
    useFormSelectArr({
      originator: "All",
    });

  const { valuesDate, handleDateChange, valuesDateDirty, updateInitialDate } =
    useFormSelectDateArr({
      startDate: new Date(moment("01-01-2024").format(DATE_FORMAT)),
      endDate: new Date(
        moment().subtract(1, "months").endOf("month").toString()
      ),
      startDate2: new Date(moment("01-01-2024").format(DATE_FORMAT)),
      endDate2: new Date(
        moment().subtract(1, "months").endOf("month").toString()
      ),
    });

  const { originator } = valuesSelect;

  const { startDate, endDate, startDate2, endDate2 } = valuesDate;

  const [tableMode, setTableMode] = useState(false);

  const { list, isLoading } = useAppSelector(
    (state) => state.foreclosureStatusReport
  );

  const dispatch = useAppDispatch();

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      !tableMode && setTableMode(true);
    }
  }, [isLoading]);

  const fields = {
    select1: {
      options: ORIGINATOR_OPTIONS_BASE,
      onSelect: (value: string) => handleSelect("originator", value),
      value: originator,
      oneInRow: true,
      fieldName: "Select Originator",
    },
    startDate: {
      date: startDate,
      setDate: (value: Date | undefined | null) =>
        handleDateChange("startDate", value),
      fieldName: "Foreclosure Sale Date",
      label: "From",
    },
    endDate: {
      date: endDate,
      setDate: (value: Date | undefined | null) =>
        handleDateChange("endDate", value),
      label: "To",
    },
    startDate2: {
      date: startDate2,
      setDate: (value: Date | undefined | null) =>
        handleDateChange("startDate2", value),
      fieldName: "Bid Due Date",
      label: "From",
    },
    endDate2: {
      date: endDate2,
      setDate: (value: Date | undefined | null) =>
        handleDateChange("endDate2", value),
      label: "To",
    },
  };

  const fieldsInPanel = {
    select1: {
      options: ORIGINATOR_OPTIONS_BASE,
      onSelect: (value: string) => onChangeSelectInPanel("originator", value),
      value: originator,
      oneInRow: true,
      fieldName: "Originator",
    },
    startDate: {
      date: startDate,
      setDate: (value: Date | undefined | null) =>
        onChangeDateInPanel("startDate", value),
      label: "Sale Date From",
    },
    endDate: {
      date: endDate,
      setDate: (value: Date | undefined | null) =>
        onChangeDateInPanel("endDate", value),
      label: "Sale Date To",
    },
    startDate2: {
      date: startDate2,
      setDate: (value: Date | undefined | null) =>
        onChangeDateInPanel("startDate2", value),
      label: "Bid Due Date From",
    },
    endDate2: {
      date: endDate2,
      setDate: (value: Date | undefined | null) =>
        onChangeDateInPanel("endDate2", value),
      label: "Bid Due Date To",
    },
  };

  const getTableList = ({
    originator,
    startDate,
    endDate,
    startDate2,
    endDate2,
  }: FormValues) => {
    if (!isLoading) {
      dispatch(
        getList({
          model: originator.toLocaleLowerCase(),
          foreclosure_sale_date_from: moment(startDate).format("YYYY-MM-DD"),
          foreclosure_sale_date_to: moment(endDate).format("YYYY-MM-DD"),
          bid_due_date_from: moment(startDate2).format("YYYY-MM-DD"),
          bid_due_date_to: moment(endDate2).format("YYYY-MM-DD"),
        })
      );

      updateInitialSelect();
      updateInitialDate();
    }
  };

  const onBackTableMode = () => setTableMode(false);

  const onDownloadFile = () => {
    const params = encodeDataToUrl({
      model: originator.toLocaleLowerCase(),
      foreclosure_sale_date_from: moment(startDate).format("YYYY-MM-DD"),
      foreclosure_sale_date_to: moment(endDate).format("YYYY-MM-DD"),
      bid_due_date_from: moment(startDate2).format("YYYY-MM-DD"),
      bid_due_date_to: moment(endDate2).format("YYYY-MM-DD"),
    });

    downloadResourceFromURL(
      `Foreclosure Status Report.xlsx`,
      `${DOWNLOAD_FORECLOSURE_STATUS}?${params}`
    );
  };

  const handleClickImportBtn = () => {
    getTableList({ originator, startDate, endDate, startDate2, endDate2 });
  };

  const onChangeSelectInPanel = (field: string, value: string) => {
    handleSelect(field, value);
    const data = {
      ...valuesSelect,
      ...valuesDate,
      [field]: value,
    };
    getTableList({ ...data });
  };

  const onChangeDateInPanel = (
    field: string,
    value: Date | undefined | null
  ) => {
    handleDateChange(field, value);
    const data = {
      ...valuesSelect,
      ...valuesDate,
      [field]: value,
    };
    getTableList({ ...data });
  };

  return (
    <PageContent
      title="Foreclosure Status Report"
      path="Accounting"
      search={tableMode}
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout tableMode={tableMode}>
        <>
          <FormBlock
            fields={fields}
            importButton={{
              label: isLoading ? "Processing..." : "Generate Report",
              onclick: handleClickImportBtn,
              disabled: tableMode
                ? isLoading ||
                  !startDate ||
                  !endDate ||
                  (!valuesSelectDirty && !valuesDateDirty)
                : isLoading,
            }}
            tableMode={tableMode}
          />
          {tableMode && (
            <TableControls
              columnsList={columnsForeclosureStatusReport}
              list={list}
              isLoading={isLoading}
              onDownloadFile={onDownloadFile}
              withFilters
              shortFilterBtn
              searchColumns={["contract_id"]}
              dynamicPinFirstCol
              formInPanel={
                <FormBlock
                  fields={fieldsInPanel}
                  tableMode
                  inControlPanel
                  sizeFields={"120"}
                />
              }
            />
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
