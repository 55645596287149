import { ReactNode } from "react";
import s from "./FiltersBlock.module.scss";

type Props = {
  title?: string;
  children: ReactNode;
};

export const FiltersInnerBlock = ({ title, children }: Props) => {
  return (
    <div className={s.blockInner}>
      {title && <h3>{title}</h3>}
      {children}
    </div>
  );
};
