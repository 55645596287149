export const BASE_API = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}`
  : "https://dev-api.kingsbridgealts.com";

export const LOGIN_API = `${BASE_API}/auth/login`;
export const REFRESH_TOKEN_API = `${BASE_API}/auth/refresh`;
export const FORGOT_PASSWORD = `${BASE_API}/auth/forgot-password`;
export const FHFA_IMPORT_API = `${BASE_API}/kwm/upload/fhfa`;
export const UPDATE_USER_API = `${BASE_API}/kwm/update/user`;
export const GET_USER_API = `${BASE_API}/kwm/user`;
export const CHANGE_USER_PASSWORD_API = `${BASE_API}/kwm/reset/user/password`;
export const FORGOT_PASSWORD_API = `${BASE_API}/kwm/forgot-password`;
export const FORGOT_PASSWORD_CONFIRMATION_API = `${BASE_API}/kwm/forgot-password-confirmation`;
export const CHECK_TOKEN = `${BASE_API}/check/token`;
export const OON_IMPORT_API = `${BASE_API}/kwm/upload/oon`;
export const DEFAULT_MONITORING_IMPORT_API = `${BASE_API}/import/default_monitoring`;
export const DETAIL_IMPORT_API = `${BASE_API}/kwm/upload/detail`;
export const TAPE_IMPORT_API = `${BASE_API}/kwm/upload/tape`;
export const PRELIMINARY_EXIST_IMPORT_API = `${BASE_API}/kwm/upload/preliminary_exist`;
export const MONTHLY_COLLECTION_IMPORT_API = `${BASE_API}/point/monthly/collections/report`;
export const ORIGINATION_FEES_SWEET_IMPORT_API = `${BASE_API}/origination/fees/sweep`;
export const THIRD_PARTY_REPORTS_IMPORT_API = `${BASE_API}/kwm/upload/hl-valuation`;
export const TITLE_POLICY_IMPORT_API = `${BASE_API}/kwm/upload/title-policy`;
export const DEMOGRAPHICS_DATA_IMPORT_API = `${BASE_API}/homeowner_information`;
export const GENERATE_PROMO_STATUS = `${BASE_API}/generate/promo/status`;
export const ACTIONS_HISTORY = `${BASE_API}/actions_history`;

// Manage users APIs
export const GET_ALL_USERS = `${BASE_API}/kwm/all/users`;
export const CREATE_USER = `${BASE_API}/kwm/create/user`;
export const EDIT_USER = `${BASE_API}/kwm/update/user`;
export const DELETE_USER = `${BASE_API}/kwm/delete/user`;

//Permissions
export const GET_PERMISSIONS = `${BASE_API}/user_permissions`;
export const UPDATE_PERMISSIONS = `${BASE_API}/update/user_permissions`;

// KASF valuation model
export const GENERATE_ASSET_LIST = `${BASE_API}/generate/asset/list`;
export const DOWNLOAD_HPA_CONTRACT_REPORT = `${BASE_API}/hpa_contract_report/download`;
export const GET_HPA_CONTRACT_LIST = `${BASE_API}/hpa_contract_report`;
export const DOWNLOAD_HPA_METRO_REPORT = `${BASE_API}/hpa_metro_report/download`;
export const GET_HPA_METRO_LIST = `${BASE_API}/hpa_metro_report`;
export const GET_CONTRACT_ID_LIST = `${BASE_API}/contract_id_list`;
export const DOWNLOAD_KASF_MODEL = `${BASE_API}/download/kafs/model`;
export const INTERNAL_VALUATION = `${BASE_API}/kasf/internal/valuation/functionality`;
export const GET_ASSET_LIST = `${BASE_API}/asset_lists`;
export const SUBMIT_AS_OFFICIAL = `${BASE_API}/selected_assets_list`;
export const S3_DOWNLOAD_FILE = `${BASE_API}/s3_file_download`;
export const THIRD_PARTY_COMPARISON_REPORT = `${BASE_API}/3rd/party/comparison/report/functionality`;
export const INTERNAL_VALUATION_COMPARISON_REPORT = `${BASE_API}/valuation/comparison_report`;
export const GET_SMM_RESOLITION_RATES_LIST = `${BASE_API}/smm_resolution_rate`;
export const DOWNLOAD_SMM_RESOLITION_RATES = `${BASE_API}/download/smm_resolution_rate`;
export const GET_FORWARD_CASHFLOWS_LIST = `${BASE_API}/forward-cash-flow-and-expected-irr`;
export const DOWNLOAD_FORWARD_CASHFLOWS_REPORT = `${BASE_API}/forward-cash-flow-and-expected-irr/download`;
export const GET_GROSS_MONTHLY_RETURNS_LIST = `${BASE_API}/gross_monthly_returns`;
export const DOWNLOAD_GROSS_MONTHLY_RETURNS_REPORT = `${BASE_API}/gross_monthly_returns/download`;

//Accounting
export const GET_ACTUAL_RETURNS_REPORT_LIST = `${BASE_API}/cash_flow`;
export const DOWNLOAD_ACTUAL_RETURNS = `${BASE_API}/download/cash_flow`;
export const GET_EXITS_VARIANCE_LIST = `${BASE_API}/exits_variance`;
export const DOWNLOAD_EXITS_VARIANCE = `${BASE_API}/download/exits_variance`;
export const GET_FORECLOSURE_STATUS_LIST = `${BASE_API}/foreclosure_sales_report`;
export const DOWNLOAD_FORECLOSURE_STATUS = `${BASE_API}/foreclosure_sales_report/download`;
export const GENERATE_MONTHLY_ROLLS = `${BASE_API}/roll_forwards`;
export const GET_IMPAIRED_LIST = `${BASE_API}/impaired_list`;
export const DOWNLOAD_IMPAIRED = `${BASE_API}/download/impaired_list`;
export const CANCEL_IMPARED_LIST = `${BASE_API}/update/impaired_list`;
export const GET_PAYOFFS_REPORT_LIST = `${BASE_API}/demand_report`;
export const DOWNLOAD_PAYOFFS_REPORT = `${BASE_API}/download/demand_report`;

// Pledge collateral
export const PLEDGE_LIST = `${BASE_API}/pledge/list`;
export const DOWNLOAD_PLEDGE_LIST_CSV = `${BASE_API}/download/pledge/list`;
export const CANCEL_PLEDGE_LIST = `${BASE_API}/cancel/pledge`;
export const DOWNLOAD_PLEDGE_CREDIT_LINE_CSV = `${BASE_API}/download/pledge/credit/line`;
export const CREATE_PLEDGE = `${BASE_API}/create/pledge`;
export const GET_DEBT_LIST = `${BASE_API}/pledge/credit/line`;

//Record Management
export const GET_PAYOFF_DEMAND_DATA = `${BASE_API}/demand_requests`;
export const GET_SEARCH_VALUES = `${BASE_API}/get_search_values`;
export const UPDATE_PAYOFF_DEMAND_DATA = `${BASE_API}/update/demand_requests`;
export const GET_UPDATE_CONTRACT_DATA = `${BASE_API}/get_contract_details`;
export const UPDATE_CONTRACT_DATA = `${BASE_API}/update_contract_details`;
export const GET_DELETE_CONTRACT_DATA = `${BASE_API}/get_info_for_del_contract`;
export const GET_LOOK_UP_CONTRACT_DATA = `${BASE_API}/lookup_contract_details`;
export const DELETE_CONTRACT_DATA = `${BASE_API}/delete_contract`;
export const GET_PRELIMINARY_EXIT_DATA = `${BASE_API}/get_exit_contract`;
export const UPDATE_PRELIMINARY_EXIT_DATA = `${BASE_API}/update_exit_contract`;

//Dashboards
export const GET_TABLEAU_TOKEN = `${BASE_API}/generate/tableau/token`;
