import { PageContent } from "components/layout/PageContent/PageContent";
import s from "./Kasf.module.scss";
import { ChangeEvent, useEffect, useState } from "react";
import { PageFormLayout } from "components/layout/PageFormLayout/PageFormLayout";
import { FormBlock, TableControls } from "components/modules";
import { useFormInputPercentageArr } from "hooks/useFormInputPercentageArr";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import moment from "moment";
import { getList } from "store/actions/ActualReturnsReportList";
import { DOWNLOAD_ACTUAL_RETURNS, columnsActualReturnsReport } from "const";
import { downloadResourceFromURL, encodeDataToUrl } from "utils";
import { useFormSelectDateArr } from "hooks/useFormSelectDateArr";
import { usePrevious } from "hooks/usePrevious";

export const ActualReturnsReport = () => {
  const { valuesInputPercent, handleChangeInputPercent, handleKeyDown } =
    useFormInputPercentageArr({
      pointServicing: "1%",
      pointDisposition: "5%",
      spliteroServicing: "1%",
      spliteroDisposition: "5%",
      point2Servicing: "1.25%",
      point2Disposition: "4%",
    });

  const { valuesDate, handleDateChange } = useFormSelectDateArr({
    startDate: new Date(
      moment().subtract(1, "months").startOf("month").toString()
    ),
    endDate: new Date(moment().subtract(1, "months").endOf("month").toString()),
  });

  const {
    pointServicing,
    pointDisposition,
    spliteroServicing,
    spliteroDisposition,
    point2Servicing,
    point2Disposition,
  } = valuesInputPercent;

  const { startDate, endDate } = valuesDate;

  const [tableMode, setTableMode] = useState(false);

  const [isOpenAssetList, toggleAssetList] = useState(false);

  const { list, isLoading } = useAppSelector(
    (state) => state.actualReturnsReportList
  );

  const dispatch = useAppDispatch();

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      !tableMode && setTableMode(true);
      isOpenAssetList && toggleAssetList(false);
    }
  }, [isLoading]);

  const fields = {
    pointInputFirst1: {
      fieldName: "Point (Schedule 1)",
      label: "Servicing Fees (Annual %)",
      value: pointServicing,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleChangeInputPercent("pointServicing", e.target.value),
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
        handleKeyDown("pointServicing", pointServicing, e),
      oneInRow: true,
    },
    pointInputSecond1: {
      label: "Disposition Fees (%)",
      value: pointDisposition,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleChangeInputPercent("pointDisposition", e.target.value),
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
        handleKeyDown("pointDisposition", pointDisposition, e),
    },
    pointInputFirst2: {
      fieldName: "Point  (Schedule 2)",
      label: "Servicing Fees (Annual %)",
      value: point2Servicing,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleChangeInputPercent("point2Servicing", e.target.value),
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
        handleKeyDown("point2Servicing", point2Servicing, e),
      oneInRow: true,
    },
    pointInputSecond2: {
      label: "Disposition Fees (%)",
      value: point2Disposition,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleChangeInputPercent("point2Disposition", e.target.value),
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
        handleKeyDown("point2Disposition", point2Disposition, e),
    },
    pointInputFirst3: {
      fieldName: "Splitero",
      label: "Servicing Fees (Annual %)",
      value: spliteroServicing,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleChangeInputPercent("spliteroServicing", e.target.value),
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
        handleKeyDown("spliteroServicing", spliteroServicing, e),
      oneInRow: true,
    },
    pointInputSecond3: {
      label: "Disposition Fees (%)",
      value: spliteroDisposition,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleChangeInputPercent("spliteroDisposition", e.target.value),
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
        handleKeyDown("spliteroDisposition", spliteroDisposition, e),
    },
    startDate: {
      date: startDate,
      setDate: (value: Date | undefined | null) =>
        handleDateChange("startDate", value),
      fieldName: "Choose Date",
      label: "Date From",
    },
    endDate: {
      date: endDate,
      setDate: (value: Date | undefined | null) =>
        handleDateChange("endDate", value),
      label: "Date To",
    },
  };

  const getTableList = () => {
    if (!isLoading) {
      dispatch(
        getList({
          date_from: moment(startDate).format("YYYY-MM-DD"),
          date_to: moment(endDate).format("YYYY-MM-DD"),
          point_servicing_fees: parseFloat(pointServicing).toString(),
          point_disposition_fees: parseFloat(pointDisposition).toString(),
          splitero_servicing_fees: parseFloat(spliteroServicing).toString(),
          splitero_disposition_fees: parseFloat(spliteroDisposition).toString(),
          tilden_servicing_fees: parseFloat(point2Servicing).toString(),
          tilden_disposition_fees: parseFloat(point2Disposition).toString(),
        })
      );
    }
  };

  const onBackTableMode = () => setTableMode(false);

  const onDownloadFile = () => {
    const params = encodeDataToUrl({
      date_from: moment(startDate).format("YYYY-MM-DD"),
      date_to: moment(endDate).format("YYYY-MM-DD"),
      point_servicing_fees: parseFloat(pointServicing).toString(),
      point_disposition_fees: parseFloat(pointDisposition).toString(),
      splitero_servicing_fees: parseFloat(spliteroServicing).toString(),
      splitero_disposition_fees: parseFloat(spliteroDisposition).toString(),
      tilden_servicing_fees: parseFloat(point2Servicing).toString(),
      tilden_disposition_fees: parseFloat(point2Disposition).toString(),
    });

    downloadResourceFromURL(
      `Actual Returns.xlsx`,
      `${DOWNLOAD_ACTUAL_RETURNS}?${params}`
    );
  };

  const handleClickImportBtn = () => {
    getTableList();
  };

  return (
    <PageContent
      title="Actual Returns Report"
      path="Accounting"
      search={tableMode}
      secondScreenMode={tableMode}
      onGoBack={onBackTableMode}
    >
      <PageFormLayout tableMode={tableMode}>
        <>
          <FormBlock
            fields={fields}
            importButton={{
              label: isLoading ? "Processing..." : "Generate",
              onclick: handleClickImportBtn,
              disabled: isLoading,
            }}
            tableMode={tableMode}
            isOpenAssetList={isOpenAssetList}
            onCloseAssetList={() => toggleAssetList(false)}
          />
          {tableMode && (
            <TableControls
              columnsList={columnsActualReturnsReport}
              list={list}
              defaultSortedColumn="effective_date"
              onDownloadFile={onDownloadFile}
              onOpenAssetList={() => toggleAssetList(true)}
              withFilters
              searchColumns={["contract_id"]}
              isLoading={isLoading}
              dynamicPinFirstCol
            />
          )}
        </>
      </PageFormLayout>
    </PageContent>
  );
};
