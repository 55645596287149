import { UpdateContractType } from "store/types/UpdateContractType";
import { FormPrimary } from "./FormPrimary";
import s from "./UpdateContract.module.scss";
import { FormSecondary } from "./FormSecondary";
import { FormForeclosure } from "./FormForeclosure";

type Props = {
  data: UpdateContractType["data"];
  isLoading: boolean;
  isUpdated: boolean;
};

export const FormsUpdateContract = ({ data, isLoading, isUpdated }: Props) => {
  return (
    <div className={s.container}>
      <FormPrimary data={data} isLoading={isLoading} isUpdated={isUpdated} />
      <FormSecondary data={data} isLoading={isLoading} isUpdated={isUpdated} />
      <FormForeclosure
        data={data}
        isLoading={isLoading}
        isUpdated={isUpdated}
      />
    </div>
  );
};
